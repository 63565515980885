import React from "react";
import Select from "react-select";
import { createEmployeeOptions } from "utils/employee_options";

type Props = {
  defaultValue?: Employee | null;
  employees: Employee[] | undefined;
  onSelectEmployee: (employee?: Employee) => void;
};

export function EmployeeComboBox(props: Props) {
  const { defaultValue, employees, onSelectEmployee } = props;

  const handleChange = (event: any) => {
    if (event) {
      onSelectEmployee(findEmployeeByName(event.label));
    }
  };

  function findEmployeeByName(employeeName: string) {
    return (
      employees?.filter((employee) => {
        return employee.name === employeeName;
      })[0] || undefined
    );
  }

  const employeeOptions = React.useMemo(() => createEmployeeOptions(employees), [employees]);

  const defaultValueFromList = employeeOptions.find((e) => e.employee.id === defaultValue?.id);

  return (
    <>
      <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Employee name</label>
      <div className="flex">
        {employeeOptions.length > 0 && (
          <Select
            className="w-64"
            defaultValue={defaultValueFromList}
            options={employeeOptions}
            menuPortalTarget={document.body}
            isClearable
            autoFocus
            onChange={handleChange}
          />
        )}
      </div>
    </>
  );
}
