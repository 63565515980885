import React from "react";
import { NavLink, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { useFetchEmployeesQuery } from "store/reducers";
import { useFetchOrganisationsQuery } from "store/reducers/organisation";
import { fetchRRUsers } from "store/reducers/rrUsers/rrUsersAPI";

import { EmployeeImportList } from "./employee_import_list";
import { EmployeesList } from "./employees_list";
import { Organisations } from "./organisations";

function Content() {
  const dispatch = useAppDispatch();
  useFetchOrganisationsQuery();
  let navigate = useNavigate();

  const { data: employees, isSuccess } = useFetchEmployeesQuery({
    include_not_linked_to_rr: true,
    include_not_linked_to_xero: true,
    date: null,
  });

  React.useEffect(() => {
    dispatch(fetchRRUsers());
  }, [dispatch]);

  function onCreateEmployee(employeeData: any) {
    if (employeeData !== undefined) {
      navigate("/settings/employees");
    }
  }

  return (
    <div className="container mx-auto">
      <div className="rp-tab-list">
        <NavLink className="rp-tab" to="/settings/employees">
          Employees
        </NavLink>
        <NavLink className="rp-tab" to="/settings/add-employee">
          Add Employee
        </NavLink>
        <NavLink className="rp-tab" to="/settings/import">
          Import
        </NavLink>
        <NavLink className="rp-tab" to="/settings/organisations">
          Organisations
        </NavLink>
      </div>
      <div className="pb-2" />
      <Routes>
        <Route path="/employees" element={isSuccess && <EmployeesList employees={employees.employees} />} />
        <Route path="/add-employee" element={<>This feature has been removed. Please use the Import tab instead.</>} />
        <Route path="/import" element={<EmployeeImportList />} />
        <Route path="/organisations" element={<Organisations />} />
        <Route path="*" element={<Navigate to="/settings/employees" />} />
      </Routes>
    </div>
  );
}

export const SettingsComponent: React.FC = () => {
  React.useEffect(() => {
    document.title = "Settings | RadPay";
  });
  return <Content />;
};
