import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmployeeName } from "components/EmployeeName";
import { OvertimeButtons } from "components/OvertimeButtons";
import { format } from "date-fns";
import React from "react";
import { useAppSelector } from "store/hooks";
import { selectPendingOvertime, useGetOvertimesQuery } from "store/reducers/overtime";

type Props = {
  startDate: Date | null;
};

export function SummaryOvertime(props: Props) {
  const { startDate } = props;

  const { error, isLoading } = useGetOvertimesQuery(
    { start_date: startDate ? format(startDate, "yyyy-MM-dd") : "" },
    { skip: !startDate, refetchOnMountOrArgChange: true },
  );

  const pendingOvertime = useAppSelector(selectPendingOvertime);

  if (!startDate) return null;

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Error loading data.</span>;
  }

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th rowSpan={1} colSpan={4} className="table-header-name">
              <FontAwesomeIcon icon={faClock} /> Overtime Requests
            </th>
          </tr>
          <tr>
            <th className="table-header-column-name w-1/12">Employee</th>
            <th className="table-header-column-name w-2/12">Date</th>
            <th className="table-header-column-name w-3/12">Notes</th>
            <th className="table-header-column-name w-1/12">Action</th>
          </tr>
        </thead>
        <tbody>
          {pendingOvertime.map((overtime) => (
            <tr key={overtime.id}>
              <td className="table-body-cell">
                <EmployeeName employeeId={overtime.employee_id} />
              </td>
              <td className="table-body-cell">{format(new Date(overtime.when), "dd-MM-yyyy")}</td>
              <td className="table-body-cell break-all">{overtime.reason}</td>
              <td className="table-body-cell">
                <OvertimeButtons
                  date={overtime.when}
                  timesheetEmployeeId={overtime.employee_id}
                  parent="SUMMARY"
                  overtime={overtime}
                  overtimeDuration={null}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
