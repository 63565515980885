import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchLeaveApplications, leaveTypeApi } from "store/reducers";
import { useFetchEmployeesQuery } from "store/reducers";
import { useGetConfigQuery } from "store/reducers/config/configAPI";
import { selectIsManager, selectTeamupIframe } from "store/reducers/config/configSlice";

import { LeaveBalances } from "./leave_balances";
import { LeaveManagement } from "./leave_management";
import { LeaveRequests } from "./leave_requests";
import { RequestLeave } from "./request_leave";
import { useEmployeeId } from "../timesheet_viewer/useStartDate";

export function LeaveComponent() {
  const { data: configData } = useGetConfigQuery();
  const iframeUrl = useAppSelector(selectTeamupIframe);
  const isManager = useAppSelector(selectIsManager);

  let [employeeId] = useEmployeeId();

  const dispatch = useAppDispatch();
  const { data: employees } = useFetchEmployeesQuery(undefined);

  let employee: Employee | undefined = React.useMemo(() => {
    if (configData?.currentEmployee?.payroll_role === "employee") {
      return configData.currentEmployee;
    }
    if (employeeId && employees) {
      return employees.employees.find((e) => e.id === employeeId);
    }
    return undefined;
  }, [employeeId, employees, configData]);

  leaveTypeApi.useGetLeaveTypesQuery(employee !== undefined ? { xero_tenantId: employee.xero_tenantId } : skipToken);

  React.useEffect(() => {
    document.title = "Leave | RadPay";
  });

  React.useEffect(() => {
    if (employee?.xero_tenantId != null) {
      dispatch(fetchLeaveApplications({ employee_id: employee.id }));
    }
  }, [employee, dispatch]);

  return (
    <div className="mb-3">
      <div className="container mx-auto mt-3">
        {employee && <span className="py-2 mr-4 text-xl font-semibold text-primary">Leave</span>}
        {employee && isManager && (
          <Link
            to={{ pathname: "/leave" }}
            className="inline-block mr-4 bg-accent hover:bg-accent text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring"
          >
            Leave Management
          </Link>
        )}

        {employee && (
          <div className="inline-block mr-4">
            <RequestLeave />
          </div>
        )}

        {!employee && isManager && (
          <div className="mt-3 bg-background">
            <span className="inline-block mr-4 py-2 text-xl font-semibold text-primary">Leave Management</span>
            {configData?.currentEmployee && (
              <Link
                to={{
                  pathname: "/leave",
                  search: "?e=" + configData.currentEmployee.id,
                }}
                className="inline-block mr-4 bg-accent hover:bg-accent text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring"
              >
                My Leave
              </Link>
            )}
            <div className="inline-block mr-4">
              <RequestLeave />
            </div>
          </div>
        )}

        {employee?.xero_tenantId != null && (
          <div className="mt-3">
            <LeaveBalances employee={employee} />
          </div>
        )}

        {employee && (
          <div className="mt-3">
            <LeaveRequests employee={employee} />
          </div>
        )}

        {isManager && !employeeId && (
          <div className="mt-3">
            <LeaveManagement />
          </div>
        )}
      </div>
      {iframeUrl && (
        <div className="mt-3 w-100 mx-3">
          <a className="float-right" href="https://teamup.com/ksoyjhxr93dg9hstm5" target="_blank">
            teamup direct link <FontAwesomeIcon icon="external-link-alt" />
          </a>
          <iframe
            src={iframeUrl}
            width="100%"
            height="800px"
            style={{
              border: "1px solid #cccccc",
            }}
            frameBorder="0"
          />
        </div>
      )}
    </div>
  );
}
