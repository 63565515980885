import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Portal } from "@radix-ui/react-portal";
import { formatISO, startOfDay } from "date-fns";
import React from "react";
import DatePicker from "react-datepicker";
import { useCreatePublicHolidayEventMutation, useUpdatePublicHolidayEventMutation } from "store/reducers";

import { Button } from "../components/button";
import { Modal, ModalProps } from "../components/modal";

type Props = {
  publicHolidayEvent?: PublicHolidayEvent;
} & Omit<ModalProps, "title">;

export function PublicHolidayEventModal(props: Props) {
  const { publicHolidayEvent, ...rest } = props;
  const [createPublicHolidayEvent] = useCreatePublicHolidayEventMutation();
  const [updatePublicHolidayEvent] = useUpdatePublicHolidayEventMutation();

  const [date, setDate] = React.useState<Date>(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (publicHolidayEvent?.date && new Date(publicHolidayEvent.date)) || startOfDay(new Date()),
  );

  const [name, setName] = React.useState<string>(publicHolidayEvent?.name || "");

  const [datePickerIsOpen, setDatePickerIsOpen] = React.useState(false);

  function toggleDatePicker() {
    setDatePickerIsOpen(!datePickerIsOpen);
  }

  function onDateChange(date: Date) {
    setDate(date);
  }

  function submit() {
    // Validate date, time
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!date || !name) {
      return;
    }
    let data: any = {
      name: name,
      date: formatISO(date, { representation: "date" }),
    };
    if (publicHolidayEvent) {
      // Edit
      updatePublicHolidayEvent({ id: publicHolidayEvent.id, data });
    } else {
      // Create new event
      createPublicHolidayEvent(data);
    }

    props.dismissModal();
  }

  return (
    <Modal
      title={publicHolidayEvent ? "Edit Public Holiday" : "Create Public Holiday"}
      contentLabel="Public Holiday Modal"
      buttons={
        <Button onClick={() => submit()} colour="accent">
          Submit
        </Button>
      }
      {...rest}
    >
      <div className="flex flex-col">
        <div className="md:flex mb-6">
          <div className="md:w-full">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Name</label>
            <input
              className="p-2 border rounded"
              type="text"
              name="name"
              placeholder="Holiday Name..."
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />
          </div>
        </div>

        <div className="md:flex mb-6">
          <div className="md:w-1/2 md:mb-0">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Date</label>
            <div className="flex">
              <DatePicker
                className={
                  "appearance-none block w-full bg-background text-grey-darker border border-primary-light rounded-l py-3 px-4 mb-3 focus:outline-none cursor-not-allowed"
                }
                selected={date}
                onChange={onDateChange}
                open={datePickerIsOpen}
                onSelect={toggleDatePicker}
                dateFormat="dd/MM/yyyy"
                // Append to document.body
                popperContainer={Portal}
                readOnly
                disabledKeyboardNavigation
              />
              <Button onClick={toggleDatePicker} colour="accent" className="mb-3 rounded-r rounded-l-none py-2 px-4">
                <FontAwesomeIcon icon="calendar-alt" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
