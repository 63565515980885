import { formatISO } from "date-fns";
import React from "react";
import { Routes, Route, useLocation, useMatch, NavLink, Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchLeaveApplications, selectAllLeaveApplications, useFetchEmployeesQuery } from "store/reducers";
import { useFetchOrganisationsQuery } from "store/reducers/organisation";
import { selectPendingOvertime } from "store/reducers/overtime";

import { PublishWorkedShifts } from "./publish_worked_shifts";
import { ShiftsForApproval, ShiftsForApprovalType } from "./ShiftsForApproval";
import { SummaryOvertime } from "./SummaryOvertime";
import { SummaryTable } from "./SummaryTable";
import { useHandleResponseError } from "../helpers/useHandleResponseError";
import { LeaveRequests } from "../leave/leave_requests";
import { PayPeriod } from "../timesheet_viewer/payperiod";
import { makeLocationPreserveParams, useStartDate } from "../timesheet_viewer/useStartDate";

export function SummaryComponent() {
  React.useEffect(() => {
    document.title = "Summary | RadPay";
  });
  const { data: orgStore } = useFetchOrganisationsQuery();
  const handleResponseError = useHandleResponseError();
  const [startDate, endDate] = useStartDate(handleResponseError);
  const location = useLocation();
  const routeMatch = useMatch("/summary/:org/:tab");
  useFetchEmployeesQuery(undefined);

  const orgTabIndex = orgStore?.organisations.findIndex((o) => o.xero_tenantId === routeMatch?.params.org);

  const firstOrg = orgStore?.organisations[0];

  return (
    <div className="container mx-auto py-5">
      <span className="py-2 mt-2 text-xl font-semibold text-primary">Summary</span>
      <div>
        Pay period: <PayPeriod startDate={startDate} endDate={endDate} />
      </div>
      {orgTabIndex != null && (
        <div className="rp-tab-list">
          {orgStore?.organisations.map((o) => {
            let pathname = `/summary/${o.xero_tenantId}`;
            if (routeMatch?.params.tab) {
              // preserve 2nd tab selection when switching orgs
              pathname += "/" + routeMatch.params.tab;
            }
            return (
              <NavLink
                to={makeLocationPreserveParams(location, {
                  pathname,
                })}
                key={o.xero_tenantId}
                className="rp-tab"
              >
                {o.xero_tenantName}
              </NavLink>
            );
          })}
        </div>
      )}
      <Routes>
        <Route path="/:org/*" element={<SummaryOrg startDate={startDate} endDate={endDate} />} />
        {firstOrg !== undefined && (
          <Route
            path=""
            element={
              <Navigate
                to={makeLocationPreserveParams(location, {
                  pathname: `/summary/${firstOrg.xero_tenantId}`,
                })}
              />
            }
          />
        )}
      </Routes>
    </div>
  );
}

type SummaryOrgProps = {
  startDate: Date | null;
  endDate: Date | null;
};

function SummaryOrg(props: SummaryOrgProps) {
  let { startDate, endDate } = props;
  const routeMatch1 = useMatch("/summary/:org/:tab");
  const routeMatch2 = useMatch("/summary/:org");

  const { data: orgStore } = useFetchOrganisationsQuery();

  const xero_tenantId = routeMatch1?.params.org ?? routeMatch2?.params.org;
  const organisation = orgStore?.organisations.find((o) => o.xero_tenantId == xero_tenantId);
  return (
    <div>
      {organisation && startDate && endDate && (
        <SummaryTabs startDate={startDate} endDate={endDate} organisation={organisation} />
      )}
    </div>
  );
}

type SummaryTabsProps = {
  startDate: Date;
  endDate: Date;
  organisation: Organisation;
};

function SummaryTabs(props: SummaryTabsProps) {
  let { startDate, endDate, organisation } = props;

  const location = useLocation();
  const routeMatchOrgTab = useMatch("/summary/:org/:tab");
  const routeMatchOrg = useMatch("/summary/:org");
  const dispatch = useAppDispatch();

  const [shiftsForApproval, setShiftsForApproval] = React.useState<ShiftsForApprovalType[]>();
  const handleResponseError = useHandleResponseError();
  const leaveApplications = useAppSelector(selectAllLeaveApplications);
  const pendingOvertime = useAppSelector(selectPendingOvertime);

  React.useEffect(() => {
    async function fetchShiftsForApproval() {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!startDate) {
        return;
      }
      let response = await fetch("/api/fetch_shifts_for_approval", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start_date: formatISO(startDate, { representation: "date" }),
          xero_tenantId: organisation.xero_tenantId,
        }),
      });
      if (response.ok) {
        let json: ShiftsForApprovalType[] = await response.json();
        setShiftsForApproval(json);
      } else {
        handleResponseError(response);
      }
    }

    fetchShiftsForApproval();
  }, [startDate, organisation]);

  React.useEffect(() => {
    dispatch(
      fetchLeaveApplications({
        xero_PayPeriodEndDate: endDate,
        xero_tenantId: organisation.xero_tenantId,
        filterType: "PENDING",
      }),
    );
  }, [endDate, organisation]);

  const org = routeMatchOrg?.params.org ?? routeMatchOrgTab?.params.org;
  if (!org) {
    return null;
  }
  const tabLinks = [
    {
      label: "Employee Summary",
      path: "/employee-summary",
      url: `/summary/${org}/employee-summary`,
    },
    {
      label: "Errors and Warnings",
      path: "/warnings",
      url: `/summary/${org}/warnings`,
    },
    {
      label: "Pending Leave Requests",
      path: "/leave-pending",
      url: `/summary/${org}/leave-pending`,
    },
    {
      label: "Pending Overtime Requests",
      path: "/overtime-pending",
      url: `/summary/${org}/overtime-pending`,
    },
  ] as const;

  const tabsIndex = tabLinks.findIndex((l) => l.url === location.pathname);

  if (tabsIndex === -1) {
    return (
      <Navigate
        to={makeLocationPreserveParams(location, {
          pathname: tabLinks[0].url,
        })}
      />
    );
  }

  return (
    <div>
      <PublishWorkedShifts
        organisation={organisation}
        startDate={startDate}
        endDate={endDate}
        disabled={shiftsForApproval && shiftsForApproval.length > 0}
        title={shiftsForApproval && shiftsForApproval.length > 0 ? "There are still shifts to approve" : undefined}
      />
      <div className="rp-tab-list">
        {tabLinks.map((l, i) => (
          <NavLink
            key={i}
            to={makeLocationPreserveParams(location, {
              pathname: `${l.url}`,
            })}
            className="rp-tab"
          >
            {l.label}
            {/* warnings */}
            {l.url === tabLinks[1].url && shiftsForApproval && shiftsForApproval.length > 0 && (
              <span className="text-danger">*</span>
            )}
            {/* leave-pending */}
            {l.url === tabLinks[2].url && leaveApplications.length > 0 && <span className="text-danger">*</span>}
            {/* overtime */}
            {l.url === tabLinks[3].url && pendingOvertime.length > 0 && <span className="text-danger">*</span>}
          </NavLink>
        ))}
      </div>
      <Routes>
        <Route
          path={tabLinks[0].path}
          element={<SummaryTable startDate={startDate} endDate={endDate} organisation={organisation} />}
        />
        <Route
          path={tabLinks[1].path}
          element={shiftsForApproval && <ShiftsForApproval shiftsForApproval={shiftsForApproval} />}
        />
        <Route path={tabLinks[2].path} element={<LeaveRequests />} />
        <Route path={tabLinks[3].path} element={<SummaryOvertime startDate={startDate} />} />
      </Routes>
    </div>
  );
}
