import classNames from "classnames";
import { TimePicker } from "components/TimePicker";
import { format, isEqual } from "date-fns";
import React from "react";

import { ShiftOrTransient } from "./shift_row";
import { Time } from "./time";
import { IconButton } from "../../components/iconButton";
import { parseTime } from "../../helpers/parseTime";

function timeFormat(time: Date) {
  return format(time, "h:mm a");
}

type ShiftTimeProps = {
  dateValue: Date | null;
} & Pick<TimeInputProps, "tooltipDate" | "fallback">;

export function ShiftTime(props: ShiftTimeProps) {
  let { dateValue, tooltipDate, fallback } = props;
  return (
    <>
      {!dateValue && fallback && <div>in progress</div>}
      {dateValue && (
        <Time time={dateValue} extraTitle={tooltipDate ? format(new Date(tooltipDate), "h:mm a") : undefined} />
      )}
    </>
  );
}

type TimesheetTimeInputProps = Pick<TimeInputProps, "onEnter" | "autoFocus" | "disabled" | "baseDate"> & {
  type: "start" | "end";
} & ShiftOrTransient;

export const TimesheetTimeInput = (props: TimesheetTimeInputProps) => {
  let { type, workedShift, transientShift, shiftObj, ...other } = props;
  const initialValue = (workedShift && workedShift[type]) || (transientShift && transientShift[type]) || null;
  const tooltipDate = (shiftObj && shiftObj[type]) || null;
  return (
    <TimeInput
      type="timesheet"
      initialValue={initialValue ? new Date(initialValue) : null}
      tooltipDate={tooltipDate}
      archived={workedShift && workedShift.archived}
      fallback={type === "end" && !!workedShift && !transientShift}
      {...other}
    />
  );
};

type TimeInputProps = {
  onEnter: (time: Date | null) => void;
  initialValue: Date | null;
  baseDate?: Date;
  type: "timesheet" | "roster" | "break";
  // Timesheet specific fields that I can't get rid of:
  archived?: boolean;
  tooltipDate?: string | null;
  fallback?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
};

export function TimeInput(props: TimeInputProps) {
  let { onEnter, initialValue, type, archived = false, tooltipDate, fallback, autoFocus, disabled, baseDate } = props;

  let _baseDate = baseDate || initialValue || new Date();

  const [editing, setEditing] = React.useState(false);
  const [value, setValue] = React.useState<string>("");
  const [dateValue, setDateValue] = React.useState<Date | null>(initialValue);
  const [valueDirty, setValueDirty] = React.useState(false);

  const [showTimePicker, setShowTimePicker] = React.useState(false);
  const [time, setTime] = React.useState(initialValue || new Date());
  const timePickerRef = React.useRef<HTMLDivElement>(null);

  let ref = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
  let hasChanged = !initialValue || (dateValue && !isEqual(dateValue, initialValue));

  React.useEffect(() => {
    if (autoFocus) {
      setEditing(true);
    }
  }, [autoFocus]);

  const handleTimeChange = (newTime: Date) => {
    setTime(newTime);
    onEnter(newTime);
    setShowTimePicker(false);
  };

  const toggleTimePicker = () => {
    setShowTimePicker(!showTimePicker);
  };

  function finalise() {
    if (!editing) return;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (value == null) {
      setDateValue(null);
    } else {
      let date = parseTime(value, _baseDate) || null;
      setDateValue(date);
      if (date) setTime(date);
    }
    setEditing(false);
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      finalise();
    }
  }

  function onBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (timePickerRef.current && !timePickerRef.current.contains(event.relatedTarget)) {
      finalise();
    }
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValueDirty(true);
    setValue(event.target.value);
  }

  // Detect outside click and close timePicker
  const handleOutsideClick = (event: MouseEvent) => {
    if (timePickerRef.current && !timePickerRef.current.contains(event.target as Node)) {
      setShowTimePicker(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  React.useEffect(() => {
    if (!editing && valueDirty) {
      // 1. up/down arrow shows when !editing
      // 2. after finalise the date may have changed and !editing
      if (!initialValue || (dateValue && !isEqual(dateValue, initialValue))) {
        // has changed
        onEnter(dateValue);
      }
    }
  }, [editing, dateValue]);

  React.useEffect(() => {
    setDateValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    if (dateValue) {
      setValue(timeFormat(dateValue));
    }
  }, [dateValue]);

  React.useEffect(() => {
    if (editing) {
      // ref.current.focus();
      ref.current.select();
    }
  }, [editing]);

  /**
   * Round up to the an increment of 10.
   * If already on a increment of 10, increase minutes by 10
   * Vice versa for down.
   */

  function onClickUp(nearestTo = 10, direction: "up" | "down") {
    if (!dateValue || disabled) return;
    let roundedMinutes;
    if (direction == "up") {
      roundedMinutes = Math.ceil(dateValue.getMinutes() / nearestTo) * nearestTo;
    }
    if (direction == "down") {
      roundedMinutes = Math.floor(dateValue.getMinutes() / nearestTo) * nearestTo;
    }
    let newValue;
    if (roundedMinutes != dateValue.getMinutes()) {
      // e.g. 12:03 -> 12:10
      newValue = new Date(
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDate(),
        dateValue.getHours(),
        roundedMinutes,
        // seconds, milliseconds are reset to 0
      );
    } else {
      // e.g. 12:10 -> 12:20
      newValue = new Date(
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDate(),
        dateValue.getHours(),
        dateValue.getMinutes() + (direction == "up" ? nearestTo : -nearestTo),
      );
    }
    onEnter(newValue);
  }

  return (
    <div
      className={classNames("flex flex-row", {
        "p-2 h-full": type === "timesheet",
      })}
    >
      {editing && (
        <div className="relative" ref={timePickerRef}>
          <input
            ref={ref}
            type="text"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onFocus={() => setShowTimePicker(false)}
            className={classNames({
              "block appearance-none w-full bg-white border border-primary hover:border-primary-dark px-4 py-2 rounded shadow leading-tight focus:outline-none":
                type === "timesheet",
              "text-danger-dark focus:border-danger-dark": type === "timesheet" && !dateValue && value,
              "appearance-none block w-full text-grey-darker border border-primary-light rounded py-3 px-4 mb-3 focus:ring":
                type === "roster",
              "appearance-none block w-28 text-grey-darker border rounded py-3 px-4 mb-3 focus:ring": type === "break",
            })}
          />
          {(type === "roster" || type === "break") && (
            <>
              <IconButton
                icon="clock"
                onClick={toggleTimePicker}
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
              />
              {showTimePicker && (
                <div className="absolute z-10 bg-white mt-1 shadow-lg">
                  <TimePicker initialTime={time} onTimeSelected={handleTimeChange} baseDate={_baseDate} />
                </div>
              )}
            </>
          )}
        </div>
      )}
      {!editing && (
        <button
          className={classNames({
            "h-full flex-grow text-center": type === "timesheet",
            italic: type === "timesheet" && hasChanged,
            "border rounded border-primary-light": type === "timesheet" && !archived,
            "line-through": type === "timesheet" && archived,
            "appearance-none block w-full bg-background text-grey-darker border border-primary-light rounded py-3 px-4 mb-3 focus:outline-none":
              type === "roster",
            "appearance-none block w-full text-grey-darker border border-primary-light rounded py-3 px-4 mb-3 focus:outline-none":
              type === "break",
          })}
          disabled={archived}
          onClick={() => !archived && setEditing(true)}
          onFocus={() => !archived && setEditing(true)}
        >
          <ShiftTime dateValue={dateValue} tooltipDate={tooltipDate} fallback={fallback} />
        </button>
      )}
      {!editing && dateValue && !archived && type === "timesheet" && (
        <div className="flex flex-col">
          <IconButton
            className="flex-1"
            icon="arrow-up"
            onMouseDown={(e) => {
              onClickUp(10, "up");
              e.preventDefault();
            }}
            disabled={disabled}
          />
          <IconButton
            className="flex-1"
            icon="arrow-down"
            onMouseDown={(e) => {
              onClickUp(10, "down");
              e.preventDefault();
            }}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
}
