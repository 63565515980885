import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Portal } from "@radix-ui/react-portal";
import { Select, SelectItem } from "components/Select";
import { formatISO, parse, startOfDay } from "date-fns";
import React from "react";
import DatePicker from "react-datepicker";
import { useCreateUnavailableEventMutation, useUpdateUnavailableEventMutation } from "store/reducers";
import { useGetConfigQuery } from "store/reducers/config/configAPI";

import { Button } from "../components/button";
import { EmployeeComboBox } from "../components/employeeComboBox";
import { Modal, ModalProps } from "../components/modal";
import { TextArea } from "../components/textarea";
import { TimeInput } from "../timesheet_viewer/table_components/time_input";

const recurrentPatterns: RecurrentPattern[] = ["daily", "weekly", "fortnightly", "monthly", "annually"];
type Props = {
  unavailableEvent?: UnavailableEvent;
  employees: Employee[];
} & Omit<ModalProps, "title">;

export function UnavailableEventModal(props: Props) {
  const { unavailableEvent, employees, ...rest } = props;
  const [createUnavailableEvent] = useCreateUnavailableEventMutation();
  const [updateUnavailableEvent] = useUpdateUnavailableEventMutation();

  const { data: configData } = useGetConfigQuery();

  const defaultEmployee = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!employees) return undefined;
    if (!unavailableEvent) return configData?.currentEmployee;
    let employee = employees.find((e) => e.id === unavailableEvent.employee_id);
    return employee;
  }, [employees, unavailableEvent, configData]);

  const [selectedEmployee, setSelectedEmployee] = React.useState<Employee | undefined>(defaultEmployee);
  const [recurrentPattern, setRecurrentPattern] = React.useState(unavailableEvent?.recurrent_pattern || "weekly");
  const [startDate, setStartDate] = React.useState<Date>(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (unavailableEvent?.start_date && new Date(unavailableEvent.start_date)) || startOfDay(new Date()),
  );
  const [endDate, setEndDate] = React.useState<Date | null>(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (unavailableEvent?.end_date && new Date(unavailableEvent.end_date)) || null,
  );
  const [startTime, setStartTime] = React.useState<Date | null>(
    (unavailableEvent?.start_time &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      parse(unavailableEvent.start_time, "HH:mm:ss", new Date(unavailableEvent.start_date))) ||
      null,
  );
  const [endTime, setEndTime] = React.useState<Date | null>(
    (unavailableEvent?.end_time &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      parse(unavailableEvent.end_time, "HH:mm:ss", new Date(unavailableEvent.start_date))) ||
      null,
  );

  const [wholeDay, setWholeDay] = React.useState<boolean>(
    !(unavailableEvent?.start_time || unavailableEvent?.end_time),
  );

  const [note, setNote] = React.useState<string>(unavailableEvent?.note || "");

  const [startDatePickerIsOpen, setStartDatePickerIsOpen] = React.useState(false);
  const [endDatePickerIsOpen, setEndDatePickerIsOpen] = React.useState(false);

  function toggleStartDatePicker() {
    setStartDatePickerIsOpen(!startDatePickerIsOpen);
  }

  function toggleEndDatePicker() {
    setEndDatePickerIsOpen(!endDatePickerIsOpen);
  }

  function onStartDateChange(date: Date) {
    setStartDate(date);
    if (endDate && endDate < date) {
      setEndDate(null);
    }
  }

  function onEndDateChange(date: Date) {
    setEndDate(date);
    if (startDate > date) {
      setStartDate(date);
    }
  }

  function submit() {
    // Validate start date, start time, end time
    if (
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !startDate ||
      (!wholeDay && (!startTime || !endTime)) ||
      !selectedEmployee
    ) {
      return;
    }
    let data: any = {
      start_date: formatISO(startDate, { representation: "date" }),
      end_date: endDate ? formatISO(endDate, { representation: "date" }) : null,
      start_time: !wholeDay && startTime ? formatISO(startTime, { representation: "time" }) : null,
      end_time: !wholeDay && endTime ? formatISO(endTime, { representation: "time" }) : null,
      recurrent_pattern: recurrentPattern,
      note: note,
      employee_id: selectedEmployee.id,
    };
    if (unavailableEvent) {
      // Edit
      updateUnavailableEvent({
        id: unavailableEvent.id,
        data,
      });
    } else {
      // Create new event
      createUnavailableEvent(data);
    }

    props.dismissModal();
  }

  return (
    <Modal
      buttons={
        <Button onClick={() => submit()} colour="accent">
          Submit
        </Button>
      }
      title={unavailableEvent ? "Edit Unavailable Event" : "New Unavailable Event"}
      {...rest}
    >
      <div className="flex flex-col">
        <div className="mb-6">
          <EmployeeComboBox
            defaultValue={defaultEmployee}
            employees={employees}
            onSelectEmployee={(employee) => setSelectedEmployee(employee)}
          />
        </div>

        {/* Start date and end date */}
        <div className="md:flex mb-6">
          <div className="md:w-1/2 md:mb-0">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Start date</label>
            <div className="flex">
              <DatePicker
                className={
                  "appearance-none block w-full bg-background text-grey-darker border border-primary-light rounded-l py-3 px-4 mb-3 focus:outline-none cursor-not-allowed"
                }
                selected={startDate}
                onChange={onStartDateChange}
                open={startDatePickerIsOpen}
                onSelect={toggleStartDatePicker}
                dateFormat="dd/MM/yyyy"
                // Append to document.body
                popperContainer={Portal}
                readOnly
                disabledKeyboardNavigation
              />
              <Button
                onClick={toggleStartDatePicker}
                colour="accent"
                className="mb-3 rounded-r rounded-l-none py-2 px-4"
              >
                <FontAwesomeIcon icon="calendar-alt" />
              </Button>
            </div>
          </div>
          <div className="md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">End date</label>
            <div className="flex">
              <DatePicker
                className={
                  "appearance-none block w-full bg-background text-grey-darker border border-primary-light rounded-l py-3 px-4 mb-3 focus:outline-none cursor-not-allowed"
                }
                selected={endDate}
                onChange={onEndDateChange}
                open={endDatePickerIsOpen}
                onSelect={toggleEndDatePicker}
                dateFormat="dd/MM/yyyy"
                popperContainer={Portal}
                readOnly
                disabledKeyboardNavigation
              />
              <Button onClick={toggleEndDatePicker} colour="accent" className="mb-3 rounded-r rounded-l-none py-2 px-4">
                <FontAwesomeIcon icon="calendar-alt" />
              </Button>
            </div>
          </div>
        </div>
        <div className="md:flex mb-6">
          <label className="md:w-1/3 block text-primary font-bold cursor-pointer mr-2">
            <input
              className="mr-2 leading-tight cursor-pointer"
              type="checkbox"
              onChange={(e) => setWholeDay(e.target.checked)}
              checked={wholeDay}
            />
            <span className="text-sm">All day</span>
          </label>
        </div>
        {/* Start time and end time. Using the same styles with time input for roster*/}
        {!wholeDay && (
          <div className="md:flex mb-6">
            <div className="md:w-1/3 md:mb-0">
              <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">From</label>
              <TimeInput type="roster" onEnter={(time) => setStartTime(time)} initialValue={startTime} />
            </div>
            <div className="md:w-1/3 px-3 md:mb-0">
              <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">To</label>
              <TimeInput type="roster" onEnter={(time) => setEndTime(time)} initialValue={endTime} />
            </div>
          </div>
        )}

        {/*Recurrence*/}
        <div className="md:flex mb-6">
          <div className="md:w-1/2 md:mb-0">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Recurrence</label>
            <Select
              onValueChange={(recurrence: RecurrentPattern) => setRecurrentPattern(recurrence)}
              value={recurrentPattern}
            >
              {recurrentPatterns.map((recurrence) => {
                return (
                  <SelectItem value={recurrence} key={recurrence}>
                    {recurrence.charAt(0).toUpperCase() + recurrence.slice(1).toLowerCase()}
                  </SelectItem>
                );
              })}
            </Select>
          </div>
        </div>

        {/*Notes*/}
        <div className="md:flex mb-6">
          <div className="md:w-full">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Notes</label>
            <TextArea
              name="Notes"
              placeholder="Message..."
              value={note}
              onChange={(e) => setNote(e.target.value)}
              maxLength={100}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
