import { skipToken } from "@reduxjs/toolkit/query";
import { Button } from "components/button";
import { SearchInput } from "components/Search";
import { formatISO } from "date-fns";
import { useRosterQueryParams } from "helpers/roster";
import React, { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  fetchDepartments,
  rosterApi,
  selectAllEmployees,
  selectIsManager,
  useFetchLocationsQuery,
} from "store/reducers";
import { useGetConfigQuery } from "store/reducers/config/configAPI";
import { useFetchEmployeesQuery, useLazyFetchEmployeesQuery } from "store/reducers/employee/employeeAPI";
import { fetchMessages } from "store/reducers/message/messageAPI";
import { Thread, selectThreads } from "store/reducers/message/messageSlice";

import { NewThread } from "./new_thread";
import { ThreadModal } from "./thread_modal";
import { CardMessage } from "../components/CardMessage";

export function Messages() {
  const { data: configData } = useGetConfigQuery();
  const [selectedThread, setSelectedThread] = React.useState<any>();

  useFetchEmployeesQuery(undefined);
  useFetchLocationsQuery();

  const [fetchEmployees] = useLazyFetchEmployeesQuery();

  const rosterQueryParams = useRosterQueryParams();
  const rosterFetchAllQuery = rosterApi.useFetchAllQuery(rosterQueryParams ?? skipToken);

  const [searchItem, setSearchItem] = React.useState("");
  const [showUnreadOnly, setShowUnreadOnly] = React.useState(false);
  const [managementMode, setManagementMode] = React.useState(false);
  const [terminatedEmployeesMode, setTerminatedEmployeesMode] = React.useState(false);
  const [sortByEmployeeName, setSortByEmployeeName] = React.useState(false);

  const dispatch = useAppDispatch();
  const threads = useAppSelector(selectThreads);
  const employees = useAppSelector(selectAllEmployees);

  const isManager = useAppSelector(selectIsManager);

  React.useEffect(() => {
    document.title = "Messages | RadPay";
    if (configData?.currentEmployee?.id) {
      dispatch(
        fetchMessages({
          management: managementMode,
          terminated_employees: terminatedEmployeesMode,
        }),
      );
    }
  }, [dispatch, configData, managementMode, terminatedEmployeesMode]);

  React.useEffect(() => {
    dispatch(fetchDepartments());
  }, [dispatch]);

  if (!rosterFetchAllQuery.isSuccess) {
    return null;
  }

  const openThreadModal = (thread: Thread) => {
    setSelectedThread(
      <ThreadModal
        thread={thread}
        dismissModal={() => setSelectedThread(undefined)}
        rosterFetchAllResponse={rosterFetchAllQuery.data}
        managementMode={managementMode}
      />,
    );
  };

  const sortedThreads = threads.slice().sort((a, b) => {
    if (sortByEmployeeName) {
      const employeeA = employees.find((e) => e.id === a.employee_id)?.name || "";
      const employeeB = employees.find((e) => e.id === b.employee_id)?.name || "";
      return employeeA.localeCompare(employeeB);
    } else {
      const aIsRead = a.read;
      const bIsRead = b.read;
      // Compare unread threads first
      if (!aIsRead && bIsRead) {
        return -1; // Move a to the front if a is unread
      } else if (aIsRead && !bIsRead) {
        return 1; // Move b to the front if b is unread
      } else {
        // Both threads are either read or unread, compare last_activity
        const aLastActivity = new Date(a.last_activity).getTime();
        const bLastActivity = new Date(b.last_activity).getTime();
        // Sort by most recent last_activity
        return bLastActivity - aLastActivity;
      }
    }
  });

  const userThreads = searchItem.trim()
    ? sortedThreads.filter((thread: Thread) => {
        const employee = employees.find((employee: Employee) => employee.id === thread.employee_id);
        return employee && employee.name.toLowerCase().includes(searchItem.toLowerCase());
      })
    : sortedThreads;

  // Apply unread filter if showUnreadOnly is true
  const filteredThreads = showUnreadOnly
    ? userThreads.filter((thread: Thread) => !thread.read && !sentByCurrentUser(thread))
    : userThreads;

  function sentByCurrentUser(thread: Thread) {
    return thread.messages[thread.messages.length - 1]?.author_id === configData?.currentEmployee?.id;
  }

  function showTerminatedEmployees(e: ChangeEvent<HTMLInputElement>) {
    const isChecked = e.target.checked;
    setTerminatedEmployeesMode(isChecked);
    if (isChecked) {
      const initialMessageDate = filteredThreads[filteredThreads.length - 1]?.messages[0]?.when;
      if (initialMessageDate) {
        const formattedDate = formatISO(new Date(initialMessageDate), {
          representation: "date",
        });
        fetchEmployees({
          date: formattedDate,
        });
      }
    }
  }

  return (
    <div className="mb-3">
      <div className="container mx-auto py-10">
        <div className="mt-3 ml-3 flex justify-between">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-semibold">Messages</h1>
            <NewThread managementMode={managementMode} title="New Thread" isGroupMessage={false} />
            {managementMode && (
              <NewThread managementMode={managementMode} title="Send Group Message" isGroupMessage={true} />
            )}
            {isManager && (
              <div>
                {managementMode && <Button onClick={() => setManagementMode(false)}>My Messages</Button>}
                {!managementMode && <Button onClick={() => setManagementMode(true)}>Message Management</Button>}
              </div>
            )}
          </div>
          {managementMode && (
            <div>
              <div>
                <SearchInput searchItem={searchItem} setSearchItem={setSearchItem} />
              </div>
              <div>
                <label className="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-gray-600"
                    checked={terminatedEmployeesMode}
                    onChange={(e) => showTerminatedEmployees(e)}
                  />
                  <span className="ml-2 text-gray-700">Show Terminated Employees</span>
                </label>
                <label className="inline-flex items-center mt-3 mx-4">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-gray-600"
                    checked={showUnreadOnly}
                    onChange={(e) => setShowUnreadOnly(e.target.checked)}
                  />
                  <span className="ml-2 text-gray-700">Show Unread Only</span>
                </label>
                <label className="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-gray-600"
                    checked={sortByEmployeeName}
                    onChange={(e) => setSortByEmployeeName(e.target.checked)}
                  />
                  <span className="ml-2 text-gray-700">Sort by Employee Name</span>
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="mt-2 flex flex-wrap justify-center">
          {filteredThreads.map((thread) => (
            <CardMessage
              key={thread.employee_id}
              thread={thread}
              sentByCurrentUser={sentByCurrentUser}
              openThreadModal={() => openThreadModal(thread)}
            />
          ))}
        </div>
      </div>
      {selectedThread}
    </div>
  );
}
