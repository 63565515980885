export function OvertimeStatus(props: { overtimeStatus: OvertimeStatus }) {
  const overtimeStatusNameMapping: {
    [key in OvertimeStatus]: string;
  } = {
    approved: "Approved",
    declined: "Declined",
    pending: "Pending",
  };
  return overtimeStatusNameMapping[props.overtimeStatus];
}
