import { format, parse } from "date-fns";
import { getUnavailableEvents } from "helpers/recurringEvent";
import React from "react";
import { useAppSelector } from "store/hooks";
import { selectUnavailableEventEntities } from "store/reducers/unavailableEvent";

import { Button } from "../components/button";

type Props = {
  unavailableEventIds: number[];
  date: Date;
};

export function UnavailableForRoster(props: Props) {
  const { unavailableEventIds, date } = props;
  const unavailableEventEntities = useAppSelector(selectUnavailableEventEntities);
  const unavailableEvents = unavailableEventIds
    .map((id) => unavailableEventEntities[id])
    .filter((obj): obj is UnavailableEvent => obj !== undefined);
  const events = getUnavailableEvents(unavailableEvents, date);

  if (!events.length) return null;

  return events.map((event: UnavailableEvent, i) => (
    <div className="text-center text-sm" key={i}>
      <Button title={event.note} disabled={true} className="mt-1 bg-warning w-full">
        Unavailable
        {!event.start_time && !event.end_time && " all day"}
        {event.start_time && event.end_time && (
          <>
            <br />
            {format(parse(event.start_time, "HH:mm:ss", new Date(date)), "h:mm a")} -
            {format(parse(event.end_time, "HH:mm:ss", new Date(date)), "h:mm a")}
          </>
        )}
      </Button>
    </div>
  ));
}
