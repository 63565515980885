import React from "react";

import { EmployeeEdit } from "./employee_edit";

export function isEmployeeUnregistered(e: Employee) {
  // We don't need to register in Xero if attendance isn't enabled
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return e.xero_tenantId == null && e.attendance_enabled;
}

type Props = {
  employees: Employee[];
};

export function EmployeesList(props: Props) {
  const { employees } = props;

  const notLinkedToXero = employees.filter((e) => isEmployeeUnregistered(e));

  const currentEmployees = employees.filter((e) => {
    return !e.xero_TerminationDate;
  });

  const terminatedEmployees = employees.filter((e) => {
    return e.xero_TerminationDate;
  });

  return (
    <div>
      {!!notLinkedToXero.length && (
        <div>
          <h1 title="More details need to be entered before these employees are created in Xero.">Unregistered</h1>
          <div className="grid grid-cols-4 gap-4">
            {notLinkedToXero.map((e) => {
              return <EmployeeEdit key={e.id} employee={e} employees={employees} />;
            })}
          </div>
        </div>
      )}
      {!!currentEmployees.length && (
        <div>
          <h1>Current Employees</h1>
          <div className="grid grid-cols-4 gap-4">
            {currentEmployees.map((e) => {
              return <EmployeeEdit key={e.id} employee={e} employees={employees} />;
            })}
          </div>
        </div>
      )}
      {!!terminatedEmployees.length && (
        <details>
          <summary>
            <h1 className="inline">Terminated Employees</h1>
          </summary>
          <div className="grid grid-cols-4 gap-4">
            {terminatedEmployees.map((e) => {
              return <EmployeeEdit key={e.id} employee={e} employees={employees} />;
            })}
          </div>
        </details>
      )}
    </div>
  );
}
