import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmployeeName } from "components/EmployeeName";
import { formatISO } from "date-fns";
import React from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "store/hooks";
import { FetchAllRosteredShiftsResponse, rosterApi, selectRosteredShiftEntities } from "store/reducers";

import { RosterDump } from "./roster_dump";
import { Button } from "../components/button";
import { Modal } from "../components/modal";

type Props = {
  startDate: Date;
  endDate: Date;
  rosterFetchAllResponse: FetchAllRosteredShiftsResponse;
} & Omit<React.ComponentPropsWithoutRef<typeof Modal>, "title">;

export function PublishRosterModal(props: Props) {
  const { startDate, endDate, rosterFetchAllResponse, ...otherProps } = props;
  const [publishRoster, publishRosterResult] = rosterApi.usePublishRosterMutation({
    // Use the same key so that the progress is shared with RosterComponent
    fixedCacheKey: "publishRoster",
  });
  const rosteredShiftEntities = useAppSelector(selectRosteredShiftEntities);

  async function publish() {
    publishRoster({
      body: {
        start: formatISO(startDate, { representation: "date" }),
        end: formatISO(endDate, { representation: "date" }),
        employee_ids: rosterFetchAllResponse.employees.map((e) => e.id),
      },
    });
    toast(
      <div>
        <strong>Publishing roster</strong>
        <p>Confirmation emails will slowly send out.</p>
        <p>
          Refresh to see the status icons (
          <FontAwesomeIcon icon="check" className="text-danger" title="Red means the email is Pending." />{" "}
          <FontAwesomeIcon icon="check" className="text-success" title="Changes to Green after email is sent." />
          ).
        </p>
      </div>,
      {
        type: "success",
        autoClose: 10000,
      },
    );
    props.dismissModal();
  }

  return (
    // TODO: show archived shifts that need to be published again
    <Modal
      title="Publish Roster"
      buttons={
        <Button className="ml-1" colour="accent" onClick={publish}>
          Publish
        </Button>
      }
      {...otherProps}
    >
      <div style={{ maxWidth: 700 }}>
        {rosterFetchAllResponse.all_employee_rostered_shifts.map((e) => {
          let publishable = e.rostered_shift_ids
            .map((id) => rosteredShiftEntities[id])
            .filter((r): r is RosteredShift => r !== undefined)
            .filter((r) => !r.emailed);
          if (publishable.length === 0) return null;
          return (
            <div key={e.employee_id}>
              <p className="font-medium">
                <EmployeeName employeeId={e.employee_id} />
              </p>
              <RosterDump rostered_shifts={publishable} />
            </div>
          );
        })}
      </div>
    </Modal>
  );
}
