import React from "react";

import { Button } from "../components/button";
import { Modal, ModalProps } from "../components/modal";
import { TextArea } from "../components/textarea";

type Props = {
  onClickArchive: any;
} & Omit<ModalProps, "title">;

export function LeaveDeclineModal(props: Props) {
  const { onClickArchive, ...rest } = props;
  const [reason, setReason] = React.useState<string>();
  return (
    <Modal
      title="Decline"
      contentLabel="Leave Decline Modal"
      buttons={
        <Button size="sm" colour="danger" onClick={() => onClickArchive(reason)}>
          Decline
        </Button>
      }
      {...rest}
    >
      <div className="md:w-full px-3">
        <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
          Reason to decline
        </label>
        <TextArea
          name="reason"
          placeholder="Reason..."
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          maxLength={100}
        />
      </div>
    </Modal>
  );
}
