import classNames from "classnames";
import { EmployeeName } from "components/EmployeeName";
import { IconButton } from "components/iconButton";
import { format } from "date-fns";
import React from "react";
import { useAppSelector } from "store/hooks";
import { selectIsManager } from "store/reducers";
import { selectTravelEventById } from "store/reducers/travelEvent";

type Props = {
  travelEventId: number;
  onArchiveTravelEvent: (travelEventId: number) => void;
};

export const TravelEvent: React.FC<Props> = (props) => {
  const { travelEventId, onArchiveTravelEvent } = props;
  const travelEvent = useAppSelector((state) => selectTravelEventById(state, travelEventId));
  const isManager = useAppSelector(selectIsManager);
  if (travelEvent === undefined) {
    return null;
  }

  return (
    <tr
      key={travelEventId}
      className={classNames({
        "line-through": travelEvent.archived,
      })}
    >
      <td className="table-body-cell">
        <p className="whitespace-nowrap">
          <EmployeeName employeeId={travelEvent.employee_id} />
        </p>
      </td>
      <td className="table-body-cell">
        <p className="whitespace-nowrap">
          {/* TODO(nesting): travelEvent.travel_to_location_id */}
          {travelEvent.travel_to_location.name}
        </p>
      </td>
      <td className="table-body-cell">
        <p className="whitespace-nowrap">
          {travelEvent.is_manual ? "-" : format(new Date(travelEvent.travel_start_time), "h:mm a EEE dd MMM yyyy ")}
        </p>
      </td>
      {isManager && !travelEvent.archived && (
        <td className="table-body-cell">
          <IconButton
            icon="trash-alt"
            title="archive"
            colour="danger"
            onClick={() => onArchiveTravelEvent(travelEvent.id)}
          />
        </td>
      )}
    </tr>
  );
};
