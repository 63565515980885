import React from "react";
import { useCreateOrganisationMutation, useFetchOrganisationsQuery } from "store/reducers/organisation";

import { useHandleResponseError } from "../helpers/useHandleResponseError";

export function Organisations() {
  let handleResponseError = useHandleResponseError();
  const [tenants, setTenants] = React.useState<Tenant[]>();

  const [createOrganisation] = useCreateOrganisationMutation();
  const { data: orgStore } = useFetchOrganisationsQuery();

  async function fetchTenants() {
    let response = await fetch("/api/tenants", {
      method: "GET",
    });
    if (!response.ok) {
      handleResponseError(response);
    } else {
      let json = await response.json();
      setTenants(json);
    }
  }

  React.useEffect(() => {
    fetchTenants();
  }, []);

  async function onClickCreate(tenant: Tenant) {
    createOrganisation({
      xero_tenantId: tenant.tenant_id,
      xero_tenantName: tenant.tenant_name,
    });
  }

  function isOrgCreated(tenant: Tenant) {
    return orgStore?.organisations.find((o) => o.xero_tenantId === tenant.tenant_id);
  }

  if (!orgStore) return null;

  return (
    <table className="table">
      <thead>
        <tr>
          <th colSpan={3} className="table-header-name">
            Available Organisations
          </th>
        </tr>
        <tr>
          <th className="table-header-column-name">Name</th>
          <th className="table-header-column-name">ID</th>
          <th className="table-header-column-name" />
        </tr>
      </thead>
      <tbody>
        {tenants &&
          tenants.map((t) => (
            <tr key={t.tenant_id}>
              <td className="table-body-cell">{t.tenant_name}</td>
              <td className="table-body-cell">{t.tenant_id}</td>
              <td className="table-body-cell">
                {!isOrgCreated(t) && (
                  <button
                    className="bg-accent hover:bg-accent-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring"
                    onClick={() => onClickCreate(t)}
                  >
                    Create
                  </button>
                )}
                {isOrgCreated(t) && "Created"}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
