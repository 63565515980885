import { EmployeeName } from "components/EmployeeName";
import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Time } from "../timesheet_viewer/table_components/time";
import { ShiftTime } from "../timesheet_viewer/table_components/time_input";
import { makeLocationPreserveParams } from "../timesheet_viewer/useStartDate";

type Props = {
  shift: WorkedShift;
  approveWhy: string;
};

export function SummaryShiftRow(props: Props) {
  let { shift, approveWhy } = props;
  const location = useLocation();

  let employeeTSLocation = makeLocationPreserveParams(location, {
    pathname: "/timesheets",
    employee_id: shift.employee_id,
  });

  return (
    <tr>
      <td className="table-body-cell">
        <Link to={employeeTSLocation}>
          <EmployeeName employeeId={shift.employee_id} />
        </Link>
      </td>
      <td className="table-body-cell">{shift.start && <Time time={new Date(shift.start)} />}</td>
      <td className="table-body-cell">
        <ShiftTime dateValue={shift.end ? new Date(shift.end) : null} fallback={true} />
      </td>
      <td className="table-body-cell">{approveWhy}</td>
    </tr>
  );
}
