import Tippy from "@tippyjs/react";
import React from "react";
import { WeekDay } from "store/reducers";
import { Duration } from "timesheet_viewer/table_components/duration";

import { IconButton } from "./iconButton";

interface HelpPayableBtnProps {
  day: WeekDay;
}

export function HelpPayableBtn({ day }: HelpPayableBtnProps) {
  if (day.auto_break === 0) {
    return null;
  }

  return (
    <Tippy
      content={
        <div>
          <Duration showZeroHours={false} seconds={day.total_worked_ordinary_duration} /> -{" "}
          <Duration showZeroHours={false} seconds={day.auto_break} /> automatic break
        </div>
      }
    >
      <IconButton tabIndex={0} icon="question-circle" />
    </Tippy>
  );
}
