import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import classNames from "classnames";
import React from "react";
import "./DropdownMenu.css";

const DropdownMenuRoot: React.FC<React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Root>> = (props) => (
  <DropdownMenuPrimitive.Root
    {...props}
    // this fixes layout shifting issues on mobile, and allows interactions with other elements while the dropdown is open
    modal={false}
  />
);

const DropdownMenuTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger>
>((props, ref) => <DropdownMenuPrimitive.Trigger {...props} ref={ref} />);
DropdownMenuTrigger.displayName = "DropdownMenuTrigger";

const DropdownMenuPortal: React.FC<React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Portal>> = (props) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content {...props} className="DropdownMenuContent" />
  </DropdownMenuPrimitive.Portal>
);

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>
>((props, ref) => (
  <DropdownMenuPrimitive.Item {...props} ref={ref} className={classNames("DropdownMenuItem", props.className)} />
));
DropdownMenuItem.displayName = "DropdownMenuItem";

export { DropdownMenuRoot, DropdownMenuTrigger, DropdownMenuPortal, DropdownMenuItem };
