import { EmployeeName } from "components/EmployeeName";
import { format, parse } from "date-fns";
import React from "react";
import {
  UnavailableEventResponse,
  useDeleteUnavailableEventMutation,
} from "store/reducers/unavailableEvent/unavailableEventApi";

import { UnavailableEventModal } from "./unavailable_event_modal";
import { Button } from "../components/button";
import { getRecurringString } from "../helpers/recurringEvent";

type Props = {
  employeeAvailability?: UnavailableEventResponse;
  employees: Employee[];
};

export function UnavailableEvents(props: Props) {
  const { employeeAvailability, employees } = props;
  const [deleteUnavailableEvent] = useDeleteUnavailableEventMutation();

  const [unavailableEventModal, setUnavailableEventModal] = React.useState<React.ReactNode>(undefined);

  function openUnavailableEventModal(event: UnavailableEvent) {
    setUnavailableEventModal(
      <UnavailableEventModal
        unavailableEvent={event}
        dismissModal={() => setUnavailableEventModal(undefined)}
        employees={employees}
      />,
    );
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th rowSpan={1} colSpan={6} className="table-header-name">
            Unavailable for Roster Shifts
          </th>
        </tr>
        <tr>
          <th className="table-header-column-name w-2/12">Employee</th>
          <th className="table-header-column-name w-2/12">Date</th>
          <th className="table-header-column-name w-2/12">Time</th>
          <th className="table-header-column-name w-1/12"> Recurrence </th>
          <th className="table-header-column-name w-3/12">Notes</th>
          <th className="table-header-column-name w-2/12" />
        </tr>
      </thead>
      <tbody>
        {employeeAvailability?.unavailable_events.map((event: UnavailableEvent, i1: number) => {
          const event_start_time = event.start_time && parse(event.start_time, "HH:mm:ss", new Date(event.start_date));
          const event_end_time = event.end_time && parse(event.end_time, "HH:mm:ss", new Date(event.start_date));
          return (
            <tr key={i1}>
              <td className="table-body-cell">
                <EmployeeName employeeId={event.employee_id} />
              </td>
              <td className="table-body-cell">
                <p>
                  {format(new Date(event.start_date), "dd/MM/yyyy")} -{" "}
                  {event.end_date ? format(new Date(event.end_date), "dd/MM/yyyy") : "N/A"}
                </p>
              </td>
              <td className="table-body-cell">
                {!event_start_time && !event_end_time && <p>All day</p>}
                {event_start_time && event_end_time && (
                  <p>
                    {format(event_start_time, "h:mm a")}-{format(event_end_time, "h:mm a")}
                  </p>
                )}
              </td>
              <td className="table-body-cell">{getRecurringString(event.start_date, event.recurrent_pattern)}</td>
              <td className="table-body-cell">{event.note}</td>
              <td className="table-body-cell">
                <Button colour="danger" onClick={() => deleteUnavailableEvent(event.id)}>
                  Archive
                </Button>
                <Button className="ml-2" colour="accent" onClick={() => openUnavailableEventModal(event)}>
                  Edit
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
      {unavailableEventModal}
    </table>
  );
}
