import Tippy from "@tippyjs/react";
import { format } from "date-fns";
import React from "react";

type Props = {
  time: Date;
  extraTitle?: string;
};

const Tooltip = (props: Props) => {
  let { time, extraTitle } = props;
  return (
    <div>
      {format(time, "EEE, dd MMM yyyy")}
      {extraTitle && <div>{extraTitle}</div>}
    </div>
  );
};

export function Time(props: Props) {
  let { time } = props;
  return (
    <Tippy content={<Tooltip {...props} />}>
      <div>{format(time, "h:mm a")}</div>
    </Tippy>
  );
}
