import { startOfDay } from "date-fns";
import React from "react";
import {
  CreateWorkedShiftBody,
  FetchShiftsResponse,
  ShiftObj,
  UpdateWorkedShiftBody,
  WeekDay,
  useCreateWorkedShiftMutation,
  useUpdateWorkedShiftMutation,
} from "store/reducers";
import { v4 } from "uuid";

import { DaySummaryRow } from "./day_summary_row";
import { RosteredShiftRow } from "./RosteredShiftRow";
import { ShiftRow } from "./shift_row";
import { EARNING_TYPES } from "../../attendance/earning_types";
import { LeaveApplicationModal } from "../../leave/leave_application_modal";
import { TravelEventsModal } from "../travel_events_modal";

type Props = {
  day: WeekDay;
  timesheetEmployee: Employee;
  startDate: Date;
  hideArchivedShifts: boolean;
  hideRosteredShifts: boolean;
  workedShiftData: FetchShiftsResponse;
  currentEmployee?: Employee;
  leaveApplications: LeaveApplication[];
};

export type OnUpdateShift = (data: Omit<UpdateWorkedShiftBody, "worked_shift_id">) => void;
export type OnClickSave = (data: CreateWorkedShiftBody) => void;

export function DayComponent(props: Props) {
  const {
    startDate,
    hideArchivedShifts,
    hideRosteredShifts,
    day,
    timesheetEmployee,
    workedShiftData,
    currentEmployee,
    leaveApplications,
  } = props;
  const [leaveModal, setLeaveModal] = React.useState<React.ReactNode>();
  const [travelEventModalIsOpen, setTravelEventModalIsOpen] = React.useState<boolean>(false);
  const [createWorkedShift] = useCreateWorkedShiftMutation();
  const [updateWorkedShift] = useUpdateWorkedShiftMutation();
  const [transientShift, setTransientShift] = React.useState<Partial<WorkedShift>>();

  const onUpdateShiftFactory: (shiftObj: ShiftObj) => OnUpdateShift = (shiftObj: ShiftObj) => (data) => {
    updateWorkedShift({
      worked_shift_id: shiftObj.worked_shift_id,
      ...data,
    });
  };

  const onUpdateTransientShift: OnUpdateShift = (data) => {
    setTransientShift({
      ...transientShift,
      ...data,
    });
  };

  function onClickCreate() {
    setTransientShift({
      id: v4(),
      employee_id: timesheetEmployee.id,
    });
  }

  let onClickSave: OnClickSave = async (data: CreateWorkedShiftBody) => {
    // For transient shifts only
    await createWorkedShift(data);
    setTransientShift(undefined);
  };

  function onClickLeave() {
    setLeaveModal(
      <LeaveApplicationModal
        dismissModal={() => setLeaveModal(undefined)}
        defaults={{
          employee_id: timesheetEmployee.id,
          start: startOfDay(new Date(day.date)),
        }}
      />,
    );
  }

  function onClickTravel() {
    setTravelEventModalIsOpen(true);
  }

  function closeTravelEventsModal() {
    setTravelEventModalIsOpen(false);
  }

  async function revertShiftToRosteredHours(rosteredShift: RosteredShift) {
    await createWorkedShift({
      employee_id: timesheetEmployee.id,
      location_id: rosteredShift.location.id,
      start: rosteredShift.start,
      end: rosteredShift.end,
      xero_EarningsRateName: rosteredShift.department.name === "Training" ? EARNING_TYPES[1].value : undefined,
    });
  }

  // We just revert the first shift for now... if there's multiple rosters the correct one is not easily known
  const firstShift = day.shifts[0];
  async function revertStartTime(rosteredShift: RosteredShift, shiftObj: ShiftObj) {
    await updateWorkedShift({
      worked_shift_id: shiftObj.worked_shift_id,
      start: rosteredShift.start,
    });
  }

  async function revertEndTime(rosteredShift: RosteredShift, shiftObj: ShiftObj) {
    await updateWorkedShift({
      worked_shift_id: shiftObj.worked_shift_id,
      end: rosteredShift.end,
    });
  }

  let shifts = day.shifts.map((shiftObj) => {
    const onUpdateShift: OnUpdateShift = onUpdateShiftFactory(shiftObj);
    return (
      <ShiftRow
        key={shiftObj.worked_shift_id}
        shiftObj={shiftObj}
        transientShift={undefined}
        hideArchivedShifts={hideArchivedShifts}
        startDate={startDate}
        day={day}
        timesheetEmployee={timesheetEmployee}
        workedShiftData={workedShiftData}
        onUpdateShift={onUpdateShift}
      />
    );
  });

  let rostered_shifts = day.rostered_shift_ids.map((rosteredShiftId) => (
    <RosteredShiftRow
      key={rosteredShiftId}
      rosteredShiftId={rosteredShiftId}
      revertShiftToRosteredHours={revertShiftToRosteredHours}
      revertToShift={firstShift}
      revertStartTime={revertStartTime}
      revertEndTime={revertEndTime}
    />
  ));

  return (
    <>
      <DaySummaryRow
        day={day}
        employee={timesheetEmployee}
        currentEmployee={currentEmployee}
        onClickCreate={onClickCreate}
        onClickLeave={onClickLeave}
        onClickTravel={onClickTravel}
        workedShiftData={workedShiftData}
        leaveApplications={leaveApplications}
      />
      {shifts}
      {transientShift && (
        <ShiftRow
          transientShift={transientShift}
          shiftObj={undefined}
          startDate={startDate}
          day={day}
          timesheetEmployee={timesheetEmployee}
          workedShiftData={workedShiftData}
          onUpdateShift={onUpdateTransientShift}
          onClickSave={onClickSave}
          hideArchivedShifts={false}
        />
      )}
      {!hideRosteredShifts && rostered_shifts}
      {leaveModal}
      <TravelEventsModal
        employee={timesheetEmployee}
        day={day}
        isOpen={travelEventModalIsOpen}
        dismissModal={closeTravelEventsModal}
      />
    </>
  );
}
