import React from "react";
import { FetchShiftsResponse, useFetchLocationsQuery } from "store/reducers";

import { DayComponent } from "./table_components/day_component";
import { TableCell } from "./table_components/table_cell";
import { TableHeader } from "./table_components/table_header";

const PlaceholderRow = () => (
  <tr>
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
  </tr>
);

type Props = {
  startDate: Date;
  timesheetEmployee?: Employee;
  workedShiftData?: FetchShiftsResponse;
  currentEmployee?: Employee;
  leaveApplications: LeaveApplication[];
};

export function TimesheetTable(props: Props) {
  const { startDate, timesheetEmployee, workedShiftData, currentEmployee, leaveApplications } = props;

  const [hideRosteredShifts, setHideRosteredShifts] = React.useState(false);
  const [hideArchivedShifts, setHideArchivedShifts] = React.useState(false);

  const { data: locations } = useFetchLocationsQuery();

  function onClickHideRosteredShifts() {
    setHideRosteredShifts(!hideRosteredShifts);
  }

  function onClickHideArchivedShifts() {
    setHideArchivedShifts(!hideArchivedShifts);
  }

  return (
    <div className="py-4">
      <div className="flex flex-row justify-end mb-2">
        <label className="block text-primary font-bold cursor-pointer mr-2">
          <input
            className="mr-2 leading-tight cursor-pointer"
            type="checkbox"
            onChange={onClickHideArchivedShifts}
            checked={hideArchivedShifts}
          />
          <span className="text-sm">Hide archived shifts</span>
        </label>
        <label className="block text-primary font-bold cursor-pointer">
          <input
            className="mr-2 leading-tight cursor-pointer"
            type="checkbox"
            onChange={onClickHideRosteredShifts}
            checked={hideRosteredShifts}
          />
          <span className="text-sm">Hide rostered shifts</span>
        </label>
      </div>

      <table className="table">
        <thead>
          <tr>
            <TableHeader />
            <TableHeader>Start</TableHeader>
            <TableHeader>End</TableHeader>
            <TableHeader>Breaks</TableHeader>
            <TableHeader>Total</TableHeader>
            <TableHeader>Type</TableHeader>
            <TableHeader>Location</TableHeader>
            <TableHeader />
          </tr>
        </thead>
        <tbody>
          {/* no-op place holder */}
          {!workedShiftData &&
            Array(14)
              .fill(0)
              .map((_, i) => <PlaceholderRow key={i} />)}
          {timesheetEmployee &&
            workedShiftData?.week_days.map((day) => {
              return (
                <DayComponent
                  key={day.day}
                  day={day}
                  startDate={startDate}
                  hideArchivedShifts={hideArchivedShifts}
                  hideRosteredShifts={hideRosteredShifts}
                  timesheetEmployee={timesheetEmployee}
                  workedShiftData={workedShiftData}
                  currentEmployee={currentEmployee}
                  leaveApplications={leaveApplications}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
