import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Badge } from "components/Badge";
import { sub, add, differenceInDays, isSameWeek } from "date-fns";
import format from "date-fns/format";
import React from "react";
import { useLocation, Link } from "react-router-dom";

import { makeLocationPreserveParams } from "./useStartDate";
import { Button } from "../components/button";

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate?: React.Dispatch<React.SetStateAction<Date>>;
};

/**
 * setStartDate:
 *  If a function is passed, don't change the router query params; invoke the callback instead.
 */
export function PayPeriod(props: Props) {
  const { startDate, endDate, setStartDate } = props;
  const location = useLocation();

  const now = new Date();
  const activePeriod =
    startDate &&
    endDate &&
    (isSameWeek(startDate, now, { weekStartsOn: 1 }) || isSameWeek(endDate, now, { weekStartsOn: 1 }));

  function getNextDate(type: "PREV" | "NEXT") {
    if (!startDate || !endDate) {
      return startDate || undefined;
    }
    let newStart: Date | undefined;
    let days = differenceInDays(endDate, startDate) + 1;
    if (type === "PREV") {
      newStart = sub(startDate, { days });
    }

    if (type === "NEXT") {
      newStart = add(startDate, { days });
    }
    return newStart;
  }

  function getNextLink(type: "PREV" | "NEXT") {
    if (!startDate || !endDate) {
      return "/timesheets";
    }
    let newStart = getNextDate(type);
    let newLocation = makeLocationPreserveParams(location, {
      startDate: newStart,
    });
    return newLocation.pathname + `?${newLocation.search}`;
  }

  return (
    <div className="text-base py-2 inline inline-flex items-center gap-1">
      {/* Normal behaviour, change the route */}
      {!setStartDate && (
        <div>
          <Link to={getNextLink("PREV")}>
            <Button colour="accent" size="lg" className="rounded-r-none px-4">
              <FontAwesomeIcon icon="chevron-left" />
            </Button>
          </Link>
          <Link to={getNextLink("NEXT")}>
            <Button colour="accent" size="lg" className="rounded-l-none px-4">
              <FontAwesomeIcon icon="chevron-right" />
            </Button>
          </Link>
        </div>
      )}
      {/* Don't change the route, dispatch to setStartDate instead */}
      {setStartDate && (
        <div>
          <Button
            onClick={() =>
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              setStartDate && setStartDate(getNextDate("PREV") as Date)
            }
            colour="accent"
            className={classNames("font-bold py-2 px-4 rounded-l rounded-r-none focus:outline-none focus:ring")}
          >
            <FontAwesomeIcon icon="chevron-left" />
          </Button>
          <Button
            onClick={() =>
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              setStartDate && setStartDate(getNextDate("NEXT") as Date)
            }
            colour="accent"
            className={classNames("font-bold py-2 px-4 rounded-r rounded-l-none focus:outline-none focus:ring")}
          >
            <FontAwesomeIcon icon="chevron-right" />
          </Button>
        </div>
      )}
      {startDate && endDate && (
        <span className={"font-semibold text-lg text-primary-dark"}>
          {format(startDate, "dd MMM yyyy")} &ndash; {format(endDate, "dd MMM yyyy")}
        </span>
      )}
      {!startDate || (!endDate && <span className="text-primary-dark font-semibold">Loading...</span>)}
      {activePeriod && <Badge colour="danger">This Period</Badge>}
      {/* <button className="bg-accent hover:bg-accent-dark text-white font-bold py-2 px-4 rounded ml-2">
                <FontAwesomeIcon icon="calendar-alt" />
              </button> */}
    </div>
  );
}
