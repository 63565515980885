import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "components/Badge";
import { eachDayOfInterval, format, formatISO, isEqual, isToday, parseISO } from "date-fns";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useAppSelector } from "store/hooks";
import { FetchAllRosteredShiftsResponse, rosterApi, useFetchPublicHolidayEventsQuery } from "store/reducers";
import { selectIsManager } from "store/reducers/config/configSlice";

import { OpenNewShiftModalFunction } from "./RosterComponent";
import { RosterShiftRow } from "./RosterShiftRow";
import { Button } from "../components/button";
import { ConfirmationModal } from "../shared/confirmation_modal";

export type OpenDeleteConfirmationModal = (employee_id: number) => void;

type Props = {
  startDate: Date;
  endDate: Date;
  rosterFetchAllData: FetchAllRosteredShiftsResponse;
  showArchivedShifts: boolean;
  addNewEmployeeRef: React.MutableRefObject<any>;
  openNewShiftModal: OpenNewShiftModalFunction;
};

export function RosterMainTable(props: Props) {
  const { startDate, endDate, rosterFetchAllData, showArchivedShifts, addNewEmployeeRef, openNewShiftModal } = props;

  const isManager = useAppSelector(selectIsManager);
  const { data: publicHolidayEvents } = useFetchPublicHolidayEventsQuery(undefined, {
    skip: !isManager,
  });

  const [confirmationModal, setConfirmationModal] = React.useState<React.ReactNode>();

  const [deleteRosteredShifts, deleteRosteredShiftsResult] = rosterApi.useDeleteMultipleRosteredShiftsMutation();

  let eachDayOfPeriod: Date[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (startDate && endDate) {
    eachDayOfPeriod = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });
  }

  const openConfirmationModal: OpenDeleteConfirmationModal = (employee_id: number) => {
    setConfirmationModal(
      <ConfirmationModal
        dismissModal={() => setConfirmationModal(undefined)}
        onClickYes={() => removeRosterShiftRow(employee_id)}
      />,
    );
  };

  function closeConfirmationModal() {
    setConfirmationModal(undefined);
  }

  async function removeRosterShiftRow(employee_id: number) {
    await deleteRosteredShifts({
      body: {
        employee_id,
        start_date: formatISO(startDate, { representation: "date" }),
        end_date: formatISO(endDate, { representation: "date" }),
      },
    });
    closeConfirmationModal();
  }

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        {rosterFetchAllData.groups.map((group) => (
          <div key={group.id}>
            <h1 id={String(group.id)}>{group.name}</h1>
            <table className="w-full mt-2">
              <thead>
                <tr>
                  <th
                    className="roster-table-header-cell"
                    style={{
                      width: `calc(100% / ${eachDayOfPeriod.length + 1})`,
                    }}
                  />
                  {eachDayOfPeriod.map((day, i) => {
                    const publicHoliday = publicHolidayEvents?.public_holiday_events.find((existing_event) =>
                      isEqual(parseISO(existing_event.date), day),
                    );
                    return (
                      <th
                        key={i}
                        className="roster-table-header-cell"
                        style={{
                          width: `calc(100% / ${eachDayOfPeriod.length + 1})`,
                        }}
                      >
                        {format(day, "EEE dd/MM")}
                        {publicHoliday && (
                          <div className="mt-1">
                            {/* normal-case undos uppercase in parent class */}
                            <Badge size="lg" colour="danger" className="normal-case">
                              Public Holiday
                            </Badge>
                          </div>
                        )}
                        {isToday(day) && (
                          <div className="mt-1">
                            <Badge size="lg" colour="danger" className="normal-case">
                              Today
                            </Badge>
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {group.employees
                  .map((employee_id) => {
                    return rosterFetchAllData.all_employee_rostered_shifts.find((e) => e.employee_id === employee_id);
                  })
                  .filter((employee_rostered_shift) => {
                    // Don't show shift row when the current user is not a manger
                    // and there is no rostered shifts for that user
                    if (!employee_rostered_shift) return false;
                    return (
                      isManager ||
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      (!isManager && employee_rostered_shift.rostered_shift_ids.length > 0)
                    );
                  })
                  .map((employee_rostered_shift, i) => {
                    return (
                      <RosterShiftRow
                        eachDayOfPeriod={eachDayOfPeriod}
                        employee_rostered_shift={employee_rostered_shift!}
                        openConfirmationModal={openConfirmationModal}
                        openNewShiftModal={openNewShiftModal}
                        showArchivedShifts={showArchivedShifts}
                        startDate={startDate}
                        endDate={endDate}
                        key={i}
                        group={group}
                      />
                    );
                  })}

                {/* Only show this when the current user is not a isManager */}
                {isManager && (
                  <tr
                    ref={addNewEmployeeRef}
                    className="border-b-2 border-l border-r border-primary-light bg-primary-lightest"
                  >
                    <td className="whitespace-nowrap" colSpan={1 + eachDayOfPeriod.length}>
                      <Button
                        colour="none"
                        textColour="text-accent"
                        onClick={() =>
                          openNewShiftModal({
                            date: startDate,
                            employee: null,
                            rosteredShift: undefined,
                          })
                        }
                      >
                        <FontAwesomeIcon icon="user-plus" /> Add New Employee
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ))}
      </DndProvider>
      {confirmationModal}
    </div>
  );
}
