import Tippy from "@tippyjs/react";
import React from "react";
import { useAppSelector } from "store/hooks";

import { Badge } from "./Badge";
import { EMPLOYMENT_BASIS } from "./EmployeeProfile";
import { selectEmployeeById } from "../store/reducers/employee";

type EmployeeNameProps = {
  employeeId: number;
};

export function EmployeeName({ employeeId }: EmployeeNameProps) {
  const employee = useAppSelector((state) => selectEmployeeById(state, employeeId));

  if (!employee) {
    return <span>Loading...</span>;
  }

  const employmentBasis = EMPLOYMENT_BASIS.find((option) => option.value === employee.xero_EmploymentBasis);

  return (
    <span className="inline-flex items-center justify-center flex-wrap">
      {employee.name}
      {employmentBasis && (
        <Tippy content={<div>{employmentBasis.label}</div>}>
          <Badge size="sm" colour="accent" className="ml-1">
            {employmentBasis.shortName}
          </Badge>
        </Tippy>
      )}
    </span>
  );
}
