import React from "react";

import { NewPublicHolidayEvent } from "./new_public_holiday_event";
import { PublicHolidayEvents } from "./public_holiday_events";
import { PublicHolidayRecommendations } from "./public_holiday_recommendations";

const PublicHolidayComponent = () => {
  React.useEffect(() => {
    document.title = "Public Holidays | RadPay";
  });

  return (
    <div className="mb-3">
      <div className="container mx-auto py-10">
        <div className="mt-3">
          <NewPublicHolidayEvent />
        </div>
        <div>
          <PublicHolidayEvents />
        </div>
        <div>
          <PublicHolidayRecommendations />
        </div>
      </div>
    </div>
  );
};

export { PublicHolidayComponent };
