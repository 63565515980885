import React from "react";

export function LeavePolicy() {
  return (
    <div className="mt-4 p-3 border rounded bg-background">
      <p className="text-lg font-semibold mb-2">Please review the leave policy before submitting a leave request</p>
      <a
        href="https://drive.google.com/file/d/15zU3m9wejGXuAjdjxGxgK3YtJNnWPJnD/view"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:underline"
      >
        View Leave Policy
      </a>
    </div>
  );
}
