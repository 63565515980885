import { addListener, combineReducers, configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { attendanceApi } from "./reducers/attendance";
import { attendanceSlice } from "./reducers/attendance/attendanceSlice";
import { configApi } from "./reducers/config/configAPI";
import { departmentSlice } from "./reducers/department/departmentSlice";
import { employeeApi } from "./reducers/employee/employeeAPI";
import { employeeSlice } from "./reducers/employee/employeeSlice";
import { leaveApplicationApi, leaveApplicationSlice } from "./reducers/leaveApplication/leaveApplicationSlice";
import { leavePeriodSlice } from "./reducers/leavePeriod/leavePeriodSlice";
import { leaveTypeApi } from "./reducers/leaveType/leaveTypeSlice";
import { locationApi } from "./reducers/location/locationAPI";
import { locationSlice } from "./reducers/location/locationSlice";
import { messageSlice } from "./reducers/message/messageSlice";
import { organisationApi } from "./reducers/organisation/organisationAPI";
import { overtimeApi, overtimeSlice } from "./reducers/overtime";
import { publicHolidayApi } from "./reducers/publicHoliday/publicHolidayApi";
import { rosterApi, rosteredShiftSlice } from "./reducers/rosteredShift";
import { rrUsersSlice } from "./reducers/rrUsers/rrUsersSlice";
import { shiftApi } from "./reducers/shift/shiftApi";
import { workedShiftSlice } from "./reducers/shift/workedShiftSlice";
import { travelEventSlice } from "./reducers/travelEvent/travelEventSlice";
import { unavailableEventApi } from "./reducers/unavailableEvent/unavailableEventApi";
import { unavailableEventSlice } from "./reducers/unavailableEvent/unavailableEventSlice";

const reducers = combineReducers({
  employee: employeeSlice.reducer,
  leaveApplication: leaveApplicationSlice.reducer,
  leavePeriod: leavePeriodSlice.reducer,
  [leaveTypeApi.reducerPath]: leaveTypeApi.reducer,
  [leaveApplicationApi.reducerPath]: leaveApplicationApi.reducer,
  [rosterApi.reducerPath]: rosterApi.reducer,
  [employeeApi.reducerPath]: employeeApi.reducer,
  rosteredShift: rosteredShiftSlice.reducer,
  unavailableEvent: unavailableEventSlice.reducer,
  [unavailableEventApi.reducerPath]: unavailableEventApi.reducer,
  rrUser: rrUsersSlice.reducer,
  department: departmentSlice.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [publicHolidayApi.reducerPath]: publicHolidayApi.reducer,
  [shiftApi.reducerPath]: shiftApi.reducer,
  [workedShiftSlice.name]: workedShiftSlice.reducer,
  [travelEventSlice.name]: travelEventSlice.reducer,
  location: locationSlice.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [organisationApi.reducerPath]: organisationApi.reducer,
  message: messageSlice.reducer,
  [attendanceApi.reducerPath]: attendanceApi.reducer,
  attendance: attendanceSlice.reducer,
  overtime: overtimeSlice.reducer,
  [overtimeApi.reducerPath]: overtimeApi.reducer,
});

// Required for dispatch(addListener(...)) to work
const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== "production",
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // It has functions inside it, so we need to ignore it.
        ignoredActions: ["listenerMiddleware/add"],
      },
    }).concat(
      listenerMiddleware.middleware,
      leaveTypeApi.middleware,
      rosterApi.middleware,
      employeeApi.middleware,
      configApi.middleware,
      publicHolidayApi.middleware,
      shiftApi.middleware,
      organisationApi.middleware,
      leaveApplicationApi.middleware,
      attendanceApi.middleware,
      unavailableEventApi.middleware,
      locationApi.middleware,
      overtimeApi.middleware,
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const addAppListener = addListener.withTypes<RootState, AppDispatch>();

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
