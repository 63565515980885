import React from "react";

import { IconButton } from "./iconButton";

export function FileUpload({
  uploadFile,
  attachment,
  removeFile,
}: {
  uploadFile: (event: React.ChangeEvent<HTMLInputElement>) => Promise<Upload | undefined>;
  attachment?: { filename: string; original_filename: string };
  removeFile: () => void;
}) {
  return (
    <div className="flex flex-col">
      {attachment && (
        <div>
          <a href={"/api/upload/" + attachment.filename} target="_blank" rel="noreferrer">
            {attachment.original_filename}
          </a>
          <IconButton icon="trash-alt" colour="danger" className="ml-2" onClick={removeFile} />
        </div>
      )}
      {!attachment && <input type="file" accept="image/*, .pdf" onChange={uploadFile} />}
    </div>
  );
}
