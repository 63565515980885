import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "components/Badge";
import { Button } from "components/button";
import { DropdownMenuPortal, DropdownMenuItem, DropdownMenuRoot, DropdownMenuTrigger } from "components/DropdownMenu";
import { min, max, differenceInDays, areIntervalsOverlapping, parseISO } from "date-fns";
import { LeaveApplicationModal } from "leave/leave_application_modal";
import { LeaveDeclineModal } from "leave/leave_decline_modal";
import { canEditLeaveApplication } from "leave/leave_requests";
import { LeavePeriod } from "leave/LeavePeriod";
import React from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  deleteLeaveApplication,
  selectLeaveApplicationById,
  updateLeaveApplication,
  useGetConfigQuery,
} from "store/reducers";
import { selectIsManager } from "store/reducers/config/configSlice";

type Props = {
  startDate: Date;
  endDate: Date;
  leaveApplicationId: number;
  parent: "TIMESHEET" | "ROSTER";
};

export function RosterLeaveRow(props: Props) {
  let { startDate, endDate, leaveApplicationId, parent } = props;

  const [leaveApplicationModal, setLeaveApplicationModal] = React.useState<React.ReactNode>();
  const [leaveDeclineModal, setLeaveDeclineModal] = React.useState<React.ReactNode>();

  const dispatch = useAppDispatch();

  const leaveApplication = useAppSelector((state) => selectLeaveApplicationById(state, leaveApplicationId));
  const isManager = useAppSelector(selectIsManager);
  const { data: configData } = useGetConfigQuery();

  function updateLeave(id: number, data: Partial<LeaveApplication>) {
    dispatch(
      updateLeaveApplication({
        leaveApplicationId: id,
        body: data,
      }),
    );
  }

  function openLeaveApplicationModal(leave: LeaveApplication) {
    setLeaveApplicationModal(<LeaveApplicationModal dismissModal={closeLeaveApplicationModal} leave={leave} />);
  }

  function closeLeaveApplicationModal() {
    setLeaveApplicationModal(undefined);
  }

  function openLeaveDeclineModal(leave: LeaveApplication) {
    setLeaveDeclineModal(
      <LeaveDeclineModal
        dismissModal={() => setLeaveDeclineModal(undefined)}
        onClickArchive={(reason: string) => {
          dispatch(
            deleteLeaveApplication({
              leaveApplicationId: leave.id,
              reason: reason,
            }),
          );
          setLeaveDeclineModal(undefined);
        }}
      />,
    );
  }

  if (!leaveApplication) return null;

  const leaveStartDate = parseISO(leaveApplication.xero_StartDate);
  // use `parseISO` because `new Date()` was wrong "10:30:00 GMT+1030" which falls outside the end interval. It should be "00:00:00".
  const leaveEndDate = parseISO(leaveApplication.xero_EndDate);

  if (
    !areIntervalsOverlapping(
      { start: startDate, end: endDate },
      { start: leaveStartDate, end: leaveEndDate },
      { inclusive: true },
    )
  ) {
    return null;
  }

  const rangeStart = max([startDate, leaveStartDate]);
  const rangeEnd = min([endDate, leaveEndDate]);
  const startDateOffset = differenceInDays(rangeStart, startDate);
  const spanningDays = differenceInDays(rangeEnd, rangeStart) + 1;

  const content = (
    <>
      <DropdownMenuRoot>
        <DropdownMenuTrigger title={leaveApplication.xero_Title || "-"} asChild>
          <Button className="w-full">
            {parent === "TIMESHEET" && "Leave Request"}
            {parent === "ROSTER" && leaveApplication.leave_period_ids[0] && (
              <>
                Leave Hours: <LeavePeriod leavePeriodId={leaveApplication.leave_period_ids[0]} />
              </>
            )}
            {leaveApplication.approved && (
              <Badge size="lg" colour="success" className="ml-1" title="The leave application has been approved">
                Approved
              </Badge>
            )}
            {leaveApplication.archived && (
              <Badge size="lg" colour="danger" className="ml-1" title="The leave application has been rejected">
                Rejected
              </Badge>
            )}
            {!leaveApplication.archived && !leaveApplication.approved && (
              <Badge size="lg" colour="accent" className="ml-1" title="Your leave request is awaiting approval">
                Pending
              </Badge>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuPortal>
          {canEditLeaveApplication({
            leaveApplication,
            currentEmployee: configData?.currentEmployee,
          }) && (
            <DropdownMenuItem className="gap-1" onSelect={() => openLeaveApplicationModal(leaveApplication)}>
              <FontAwesomeIcon icon="edit" /> Edit
            </DropdownMenuItem>
          )}
          {isManager && !leaveApplication.approved && !leaveApplication.archived && (
            <DropdownMenuItem
              className="gap-1"
              onSelect={() =>
                updateLeave(leaveApplication.id, {
                  ...leaveApplication,
                  approved: true,
                })
              }
            >
              <FontAwesomeIcon icon="check" /> Approve
            </DropdownMenuItem>
          )}
          {isManager && leaveApplication.approved && !leaveApplication.archived && (
            <DropdownMenuItem
              className="gap-1"
              onSelect={() =>
                updateLeave(leaveApplication.id, {
                  ...leaveApplication,
                  approved: false,
                })
              }
            >
              <FontAwesomeIcon icon="times" /> Unapprove
            </DropdownMenuItem>
          )}
          {isManager && !leaveApplication.approved && !leaveApplication.archived && (
            <DropdownMenuItem className="gap-1" onSelect={() => openLeaveDeclineModal(leaveApplication)}>
              <FontAwesomeIcon icon="trash-alt" /> Decline
            </DropdownMenuItem>
          )}
          {isManager && !leaveApplication.approved && leaveApplication.archived && (
            <DropdownMenuItem
              className="gap-1"
              onSelect={() =>
                updateLeave(leaveApplication.id, {
                  ...leaveApplication,
                  archived: false,
                })
              }
            >
              <FontAwesomeIcon icon="trash-restore" /> Undecline
            </DropdownMenuItem>
          )}
        </DropdownMenuPortal>
      </DropdownMenuRoot>
      {leaveApplicationModal}
      {leaveDeclineModal}
    </>
  );

  if (parent === "ROSTER") {
    return (
      <tr className="roster-table-leave-row">
        <td />
        {startDateOffset > 0 && <td colSpan={startDateOffset} />}
        <td colSpan={spanningDays}>{content}</td>
        <td colSpan={8 - startDateOffset - spanningDays} />
      </tr>
    );
  } else {
    return content;
  }
}
