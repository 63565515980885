import Tippy from "@tippyjs/react";
import { formatISO } from "date-fns";
import React from "react";
import { useAppSelector } from "store/hooks";
import {
  CreateWorkedShiftBody,
  FetchShiftsResponse,
  ShiftObj,
  WeekDay,
  selectWorkedShiftById,
  useDeleteWorkedShiftMutation,
  useUpdateWorkedShiftMutation,
} from "store/reducers";
import { useGetConfigQuery } from "store/reducers/config/configAPI";
import { selectIsManager } from "store/reducers/config/configSlice";

import { BreakPopover, breakPopoverDisabled } from "./BreakPopover";
import { OnClickSave, OnUpdateShift } from "./day_component";
import { Duration } from "./duration";
import { DurationInput } from "./duration_input";
import { ShiftStatus } from "./shift_status";
import { TableCell } from "./table_cell";
import { TimesheetTimeInput } from "./time_input";
import { EarningTypes } from "../../attendance/earning_types";
import { LocationDropdown } from "../../attendance/LocationDropdown";
import { IconButton } from "../../components/iconButton";
import { ConfirmationModal } from "../../shared/confirmation_modal";
import { NotesModal } from "../notes_modal";

export type ShiftType = "rostered_shift";

type ValidShift = Pick<CreateWorkedShiftBody, "id" | "start" | "end" | "employee_id">;

function shiftIsValid(worked_shift?: Partial<WorkedShift>): worked_shift is ValidShift {
  return Boolean(worked_shift && worked_shift.start && worked_shift.end);
}

type ShiftObjOrTransient =
  | {
      // It's one or the other. Must use `const` to destructure otherwise it doesn't narrow.
      shiftObj: ShiftObj;
      transientShift: undefined;
    }
  | {
      shiftObj: undefined;
      transientShift: Partial<WorkedShift>;
    };

type Props = {
  shiftType?: ShiftType;
  startDate: Date;
  day: WeekDay;
  timesheetEmployee: Employee;
  workedShiftData: FetchShiftsResponse;
  onUpdateShift: OnUpdateShift;
  onClickSave?: OnClickSave;
  hideArchivedShifts: boolean;
} & ShiftObjOrTransient;

export type ShiftOrTransient =
  | {
      shiftObj: ShiftObj;
      workedShift: WorkedShift;
      transientShift: undefined;
    }
  | {
      shiftObj: undefined;
      workedShift: undefined;
      transientShift: Partial<WorkedShift>;
    };

export function ShiftRow(props: Props) {
  const {
    startDate,
    day,
    timesheetEmployee,
    shiftObj,
    transientShift,
    shiftType,
    workedShiftData,
    onUpdateShift,
    onClickSave,
    hideArchivedShifts,
  } = props;
  const workedShift = useAppSelector((state) =>
    shiftObj ? selectWorkedShiftById(state, shiftObj.worked_shift_id) : undefined,
  );
  /**
   * This hack groups the data together so we can satisfy the either or type, e.g. when passing to TimesheetTimeInput
   */
  const data: ShiftOrTransient = {
    shiftObj,
    workedShift,
    transientShift,
  } as ShiftOrTransient;
  let approveWhy = (workedShift && workedShiftData.to_be_approved[workedShift.id]) || [];
  let [notesModalIsOpen, setNotesModalIsOpen] = React.useState(false);
  const [confirmationModal, setConfirmationModal] = React.useState<React.ReactNode>();
  const { data: configData } = useGetConfigQuery();
  const isManager = useAppSelector(selectIsManager);
  const [updateWorkedShift, { isLoading: updating }] = useUpdateWorkedShiftMutation();
  const [deleteWorkedShift] = useDeleteWorkedShiftMutation();

  // TODO: create the shift as soon as it's valid
  React.useEffect(() => {
    if (transientShift && shiftIsValid(transientShift)) {
      onClickSaveTransient();
    }
  }, [transientShift]);

  function onChangeLocation(e: React.ChangeEvent<HTMLSelectElement>) {
    let locationId = e.target.value;
    onClickUpdate({
      location_id: locationId === "" ? null : Number(locationId),
    });
  }

  function onChangeEarningsType(e: React.ChangeEvent<HTMLSelectElement>) {
    let xero_EarningsRateName = e.target.value;
    onClickUpdate({
      xero_EarningsRateName: xero_EarningsRateName === "" ? null : xero_EarningsRateName,
    });
  }

  async function onClickSaveTransient() {
    if (transientShift && shiftIsValid(transientShift)) {
      if (!onClickSave) {
        throw new Error("onClickSave must be defined to save transient shifts");
      }
      onClickSave(transientShift);
    }
  }

  async function onClickUpdate(...data: Parameters<OnUpdateShift>) {
    onUpdateShift(...data);
  }

  async function onClickDelete() {
    if (!workedShift) return;
    await deleteWorkedShift({
      worked_shift_id: workedShift.id,
      are_you_sure: true,
    });
    closeConfirmationModal();
  }

  async function onClickApprove() {
    if (!workedShift) return;
    if (approveWhy.includes("unresolved shift_notes")) {
      openNotesModal();
    } else {
      await updateWorkedShift({
        worked_shift_id: workedShift.id,
        approved: true,
      });
    }
  }

  function openNotesModal() {
    setNotesModalIsOpen(true);
  }

  function closeNotesModal() {
    setNotesModalIsOpen(false);
  }

  function openConfirmationModal() {
    setConfirmationModal(
      <ConfirmationModal dismissModal={() => setConfirmationModal(undefined)} onClickYes={onClickDelete} />,
    );
  }

  function closeConfirmationModal() {
    setConfirmationModal(undefined);
  }

  const canApproveShift =
    configData?.currentEmployee &&
    isManager &&
    !transientShift &&
    approveWhy.length > 0 &&
    (configData.currentEmployee.payroll_role === "owner" ||
      (workedShift && workedShift.employee_id != configData.currentEmployee.id));

  if (hideArchivedShifts && workedShift && workedShift.archived) {
    return null;
  }

  return (
    <tr style={{ height: "3.5em" }}>
      <TableCell workedShift={workedShift} shiftType={shiftType}>
        <div>
          {workedShift && <ShiftStatus worked_shift={workedShift} workedShiftData={workedShiftData} />}
          {workedShift && workedShift.shift_notes.length !== 0 && (
            <IconButton onClick={() => openNotesModal()} icon="comments" className="ml-1" colour="primary" />
          )}
        </div>
      </TableCell>
      <TableCell workedShift={workedShift} shiftType={shiftType}>
        <div className="absolute top-0 left-0 h-full w-full">
          <TimesheetTimeInput
            type="start"
            {...data}
            baseDate={new Date(day.date)}
            onEnter={(time) =>
              onClickUpdate({
                start: time ? formatISO(time, { representation: "complete" }) : undefined,
              })
            }
            disabled={updating}
            autoFocus={Boolean(transientShift)}
          />
        </div>
      </TableCell>
      <TableCell workedShift={workedShift} shiftType={shiftType}>
        <div className="absolute top-0 left-0 h-full w-full">
          <TimesheetTimeInput
            type="end"
            {...data}
            baseDate={new Date(day.date)}
            onEnter={(time) =>
              onClickUpdate({
                end: time ? formatISO(time, { representation: "complete" }) : undefined,
              })
            }
            disabled={updating}
          />
        </div>
      </TableCell>
      <TableCell workedShift={workedShift} shiftType={shiftType}>
        {workedShift && (
          <div className="absolute top-0 left-0 h-full w-full">
            <Tippy content={<BreakPopover {...data} />} disabled={breakPopoverDisabled(workedShift)}>
              <DurationInput {...data} onEnter={(time) => onClickUpdate({ break_duration: time })} />
            </Tippy>
          </div>
        )}
      </TableCell>
      <TableCell workedShift={workedShift} shiftType={shiftType}>
        {shiftObj && <Duration seconds={shiftObj.total_worked_duration} />}
      </TableCell>
      <TableCell workedShift={workedShift} shiftType={shiftType}>
        {workedShift && (
          <>
            {timesheetEmployee.earnings_vocational && (
              <EarningTypes
                showLabel={false}
                onChangeEarningsType={onChangeEarningsType}
                earningsRate={workedShift.xero_EarningsRateName ?? ""}
                disabled={workedShift.archived}
              />
            )}
            {!timesheetEmployee.earnings_vocational && <span>{workedShift.xero_EarningsRateName || "Ordinary"}</span>}
          </>
        )}
      </TableCell>
      <TableCell workedShift={workedShift} shiftType={shiftType}>
        {workedShift && (
          <LocationDropdown
            onChange={onChangeLocation}
            value={workedShift.location?.id ?? ""}
            disabled={workedShift.archived}
          />
        )}
      </TableCell>
      <TableCell>
        {transientShift && shiftIsValid(transientShift) && (
          <IconButton onClick={onClickSaveTransient} title="Save" colour="accent" size="lg" icon="save" />
        )}
        {workedShift && !workedShift.archived && (
          <IconButton onClick={openConfirmationModal} title="Archive" colour="danger" size="lg" icon="trash-alt" />
        )}
        {workedShift && (
          <>
            <IconButton
              onClick={() => openNotesModal()}
              title="Add/Check notes"
              colour="accent"
              size="lg"
              icon="comments"
            />
            {notesModalIsOpen && (
              <NotesModal isOpen={notesModalIsOpen} dismissModal={closeNotesModal} workedShift={workedShift} />
            )}
          </>
        )}
        {canApproveShift && (
          <IconButton onClick={onClickApprove} title="Approve" colour="success" size="lg" icon="check" />
        )}
      </TableCell>
      {confirmationModal}
    </tr>
  );
}
