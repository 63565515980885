import classNames from "classnames";
import { Argument as ClassNamesArgument } from "classnames";
import React from "react";

export type ColourType = "primary" | "accent" | "success" | "danger" | "none" | "warning";
type Props = {
  colour?: ColourType;
  textColour?: ClassNamesArgument;
  size?: "icon" | "sm" | "lg";
  children?: React.ReactNode;
  className?: string;
} & React.ComponentPropsWithoutRef<"button">;

export function getBgColourClasses(options: { colour: ColourType; disabled: boolean; hover?: boolean }) {
  const { colour, disabled, hover = true } = options;
  const classes = [];
  if (colour === "primary") {
    if (disabled) {
      classes.push("cursor-not-allowed");
      classes.push("bg-primary-light");
    } else {
      classes.push("bg-primary");
      classes.push("text-white");
    }
    if (hover) {
      classes.push("hover:bg-primary-dark");
    }
  } else if (colour === "accent") {
    if (disabled) {
      classes.push("cursor-not-allowed");
      classes.push("bg-accent-light");
    } else {
      classes.push("bg-accent");
      classes.push("text-white");
    }
    if (hover) {
      classes.push("hover:bg-accent-dark");
    }
  } else if (colour === "success") {
    if (disabled) {
      classes.push("cursor-not-allowed");
      classes.push("bg-success-light");
    } else {
      classes.push("bg-success");
      classes.push("text-white");
    }
    if (hover) {
      classes.push("hover:bg-success-dark");
    }
  } else if (colour === "danger") {
    if (disabled) {
      classes.push("cursor-not-allowed");
      classes.push("bg-danger-light");
    } else {
      classes.push("bg-danger");
      classes.push("text-white");
    }
    if (hover) {
      classes.push("hover:bg-danger-dark");
    }
  } else if (colour === "warning") {
    if (disabled) {
      classes.push("cursor-not-allowed");
      classes.push("bg-warning-light");
    } else {
      classes.push("bg-warning");
      classes.push("text-white");
    }
    if (hover) {
      classes.push("hover:bg-warning-dark");
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (colour === "none") {
    classes.push("bg-none");
  }
  return classes;
}

export const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  let {
    colour = "primary",
    textColour = "text-white",
    size = "lg",
    className = "", // custom class
    children,
    ...otherProps
  } = props;
  // otherProps could have onClick, disabled, etc
  const backgroundColors = getBgColourClasses({ colour, disabled: otherProps.disabled ?? false });
  return (
    <button
      ref={ref}
      {...otherProps}
      className={classNames(
        "font-semibold rounded focus:outline-none focus:ring",
        textColour,
        className,
        backgroundColors,
        {
          "py-1 px-2": size === "sm",
          "py-2 px-3": size === "lg",
          "p-0": size === "icon",
        },
      )}
    >
      {children}
    </button>
  );
});

Button.displayName = "Button";
