import Tippy from "@tippyjs/react";
import React from "react";
import { WeekDay } from "store/reducers";
import { Duration } from "timesheet_viewer/table_components/duration";

import { IconButton } from "./iconButton";

interface HelpRosterBtnProps {
  day: WeekDay;
}

export function HelpRosterBtn({ day }: HelpRosterBtnProps) {
  if (day.expected_breaks_duration === 0) {
    return null;
  }
  if (day.total_roster_duration === null) {
    return null;
  }

  return (
    <Tippy
      content={
        <div>
          <Duration showZeroHours={false} seconds={day.total_roster_duration} /> -{" "}
          <Duration showZeroHours={false} seconds={day.expected_breaks_duration} /> rostered break
        </div>
      }
    >
      <IconButton tabIndex={0} icon="question-circle" />
    </Tippy>
  );
}
