import classNames from "classnames";
import React from "react";
import { useAppSelector } from "store/hooks";
import { AllEmployeeRosteredShifts, RosteredShiftGroup, selectEmployeeById } from "store/reducers";

import { RosterLeaveRow } from "./roster-leave-row";
import { OpenDeleteConfirmationModal } from "./roster-main-table";
import { RosterNameCell } from "./roster-name-cell";
import { OpenNewShiftModalFunction } from "./RosterComponent";
import { RosterShiftDay } from "./RosterShiftDay";
import { UnavailableForRoster } from "./unavailable_for_roster";

export const ROSTER_SHIFT_ROW_CLASS = "roster-shift-row";

type Props = {
  eachDayOfPeriod: Date[];
  employee_rostered_shift: AllEmployeeRosteredShifts;
  showArchivedShifts: boolean;
  startDate: Date;
  endDate: Date;
  openConfirmationModal: OpenDeleteConfirmationModal;
  openNewShiftModal: OpenNewShiftModalFunction;
  group: RosteredShiftGroup;
};

export function RosterShiftRow(props: Props) {
  const {
    eachDayOfPeriod,
    employee_rostered_shift,
    openConfirmationModal,
    openNewShiftModal,
    showArchivedShifts,
    startDate,
    endDate,
    group,
  } = props;

  const employee = useAppSelector((state) => selectEmployeeById(state, employee_rostered_shift.employee_id));

  return (
    <>
      <tr
        className={classNames(ROSTER_SHIFT_ROW_CLASS, "border-t-2 border-primary-light h-16")}
        data-employee_id={employee_rostered_shift.employee_id}
      >
        <RosterNameCell
          // @ts-expect-error TS2322
          employee={employee}
          employee_rostered_shift={employee_rostered_shift}
          openConfirmationModal={openConfirmationModal}
        />
        {eachDayOfPeriod.map((date, i) => {
          return (
            <RosterShiftDay
              key={i}
              date={date}
              rosteredShiftIds={employee_rostered_shift.rostered_shift_ids}
              // @ts-expect-error TS2322
              employee={employee}
              group={group}
              showArchivedShifts={showArchivedShifts}
              openNewShiftModal={openNewShiftModal}
            />
          );
        })}
      </tr>
      <tr className="roster-table-leave-row">
        <td />
        {eachDayOfPeriod.map((date, i) => {
          if (!employee_rostered_shift.unavailable_event_ids.length) return <td key={i} />;
          return (
            <td key={i}>
              <UnavailableForRoster date={date} unavailableEventIds={employee_rostered_shift.unavailable_event_ids} />
            </td>
          );
        })}
      </tr>
      {employee_rostered_shift.leave_application_ids.map((leaveId) => (
        <RosterLeaveRow
          key={leaveId}
          startDate={startDate}
          endDate={endDate}
          leaveApplicationId={leaveId}
          parent="ROSTER"
        />
      ))}
    </>
  );
}
