import React from "react";
import Select from "react-select";

export class RoleFilter {
  constructor(
    public label: string,
    public value: string,
    public companyRoleIds: Set<string>,
    public any = false,
  ) {}

  includesCompanyRole(role_id: string) {
    return this.companyRoleIds.has(role_id);
  }
}

class RoleFilterAll extends RoleFilter {
  includesCompanyRole() {
    return true;
  }
}

export const ROLE_FILTERS: RoleFilter[] = [
  new RoleFilter("Radiographer", "RAD", new Set(["junior_radiographer", "radiographer"])),
  new RoleFilter("Technician", "TECH", new Set(["imaging_technician"])),
  new RoleFilter("Sonographer", "SONO", new Set(["junior_sonographer", "sonographer"])),
  new RoleFilter("Administration", "ADMIN", new Set(["clerical"])),
  new RoleFilter("IT Developer", "IT", new Set(["it_developer"])),
  new RoleFilter("Doctor", "DOCTOR", new Set(["doctor"])),
  new RoleFilter("Construction Technician", "CONST", new Set(["construction_technician", "construction_manager"])),
];

export let ALL_ROLE_FILTERS: RoleFilter[] = [new RoleFilterAll("All", "ALL", new Set())];
ALL_ROLE_FILTERS = ALL_ROLE_FILTERS.concat(ROLE_FILTERS);

type Props = {
  roleFilter: RoleFilter[];
  onChangeFilter: (value: RoleFilter[]) => void;
};

export const EmployeeFilterSelect = (props: Props) => {
  const { onChangeFilter, roleFilter } = props;

  return (
    <>
      <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Role Filter</label>
      <Select
        closeMenuOnSelect={false}
        menuPortalTarget={document.body}
        isMulti
        options={ROLE_FILTERS}
        value={roleFilter}
        // when you click "x" on the label, we get null here instead of the expected []
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        onChange={(value) => onChangeFilter((value as RoleFilter[]) || [])}
        placeholder={<>Select role&hellip;</>}
      />
    </>
  );
};
