import { Badge } from "components/Badge";
import { HelpPayableBtn } from "components/HelpPayableBtn";
import { HelpRosterBtn } from "components/HelpRosterBtn";
import { OvertimeButtons } from "components/OvertimeButtons";
import { endOfDay, format, isToday, parseISO, startOfDay } from "date-fns";
import React from "react";
import { RosterLeaveRow } from "roster/roster-leave-row";
import { UnavailableForRoster } from "roster/unavailable_for_roster";
import { useAppSelector } from "store/hooks";
import { FetchShiftsResponse, WeekDay } from "store/reducers";
import { selectOvertimeById } from "store/reducers/overtime";

import { Duration } from "./duration";
import { Button } from "../../components/button";
import { IconButton } from "../../components/iconButton";

type Props = {
  day: WeekDay;
  employee: Employee;
  onClickCreate: React.ComponentProps<typeof Button>["onClick"];
  onClickLeave: React.ComponentProps<typeof Button>["onClick"];
  onClickTravel: React.ComponentProps<typeof Button>["onClick"];
  currentEmployee?: Employee;
  workedShiftData: FetchShiftsResponse;
  leaveApplications: LeaveApplication[];
};

export function DaySummaryRow({
  day,
  employee,
  onClickCreate,
  onClickLeave,
  onClickTravel,
  currentEmployee,
  workedShiftData,
  leaveApplications,
}: Props) {
  const dayIsToday = isToday(new Date(day.date));

  const overTimeId = day.overtime_ids[0];

  const overtime = useAppSelector((state) => {
    if (overTimeId) {
      return selectOvertimeById(state, overTimeId);
    }
    return undefined;
  });

  const unavailableEventIds = workedShiftData.unavailable_events.map((event) => event.id);

  // Set the time to 00:00:00 to prevent overlaps when passing the date to getUnavailableEvents
  const date = new Date(day.date);
  date.setHours(0, 0, 0, 0);

  const dayDate = parseISO(day.date);
  const dayStart = startOfDay(dayDate);
  const dayEnd = endOfDay(dayDate);

  return (
    <tr key={"date-row-" + day.day} className="whitespace-nowrap text-primary-dark">
      <td className="ml-2 p-2 text-sm font-bold" colSpan={4}>
        <span className="flex gap-1 items-center">
          {format(new Date(day.date), "EEE, dd MMM yyyy")}
          <UnavailableForRoster unavailableEventIds={unavailableEventIds} date={date} />
          {dayIsToday && (
            <Badge className="ml-2" colour="danger" size="lg">
              Today
            </Badge>
          )}
          {leaveApplications.map(
            (leaveApplication) =>
              !leaveApplication.archived && (
                <RosterLeaveRow
                  key={leaveApplication.id}
                  startDate={dayStart}
                  endDate={dayEnd}
                  leaveApplicationId={leaveApplication.id}
                  parent="TIMESHEET"
                />
              ),
          )}
        </span>
      </td>
      <td className="text-center text-sm font-bold" colSpan={1}>
        {day.shifts.length > 0 && (
          <div>
            <Duration seconds={day.paid_ordinary_duration} /> payable <HelpPayableBtn day={day} />
          </div>
        )}
        {day.payable_roster_duration !== null && (
          <div>
            <Duration seconds={day.payable_roster_duration} /> rostered <HelpRosterBtn day={day} />
          </div>
        )}
        {day.payable_roster_duration === null && <div>No roster</div>}
        {day.overtime_duration !== null && (
          <span>
            <Duration seconds={day.overtime_duration} /> overtime
          </span>
        )}
      </td>
      <td colSpan={1} />
      <td className="text-center text-sm font-bold" colSpan={1}>
        {day.shifts.length > 0 && (
          <>
            {day.travel_count} travel unit
            <IconButton
              icon="car-side"
              colour="accent"
              className="ml-2"
              onClick={onClickTravel}
              title="Click to edit travel count"
            />
          </>
        )}
      </td>
      <td className="bg-background text-center text-sm font-bold" colSpan={1}>
        <IconButton colour="accent" size="lg" onClick={onClickCreate} title="Create a new shift" icon="plus" />
        <IconButton
          colour="accent"
          size="lg"
          onClick={onClickLeave}
          title="Create a leave request"
          icon="plane-departure"
        />
        <OvertimeButtons
          date={day.date}
          timesheetEmployeeId={employee.id}
          currentEmployeeId={currentEmployee?.id}
          overtime={overtime}
          parent="TIMESHEET"
          overtimeDuration={day.overtime_duration}
        />
      </td>
    </tr>
  );
}
