import React from "react";
import { useCreateOvertimeMutation } from "store/reducers/overtime";

import { Duration } from "./table_components/duration";
import { Button } from "../components/button";
import { Modal, ModalProps } from "../components/modal";
import { TextArea } from "../components/textarea";

type Props = {
  day: string;
  employeeId: number;
  title: string;
  overtimeDuration: number | null;
} & ModalProps;

export function OvertimeNoteModal(props: Props) {
  const { day, dismissModal, employeeId, title, overtimeDuration, ...reactModalProps } = props;
  const [note, setNote] = React.useState("");

  if (overtimeDuration === null) {
    // We don't render this component if there is no overtime duration
    throw new Error("Overtime duration is null");
  }

  const [createOvertime] = useCreateOvertimeMutation();

  function applyOvertime() {
    createOvertime({
      employee_id: employeeId,
      when: day,
      reason: note,
    })
      .unwrap()
      .then(() => {
        dismissModal();
      })
      .catch((error) => {
        console.error("Error applying overtime", error);
      });
  }

  return (
    <Modal {...reactModalProps} dismissModal={dismissModal} title={title}>
      <div className="w-full rounded-lg bg-white">
        <div className="mb-4 flex flex-row">
          <span>
            Applying for <Duration seconds={overtimeDuration} /> overtime
          </span>
        </div>

        <div className="md:flex mb-2">
          <div className="md:w-full">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
              Please provide a reason for your overtime work
            </label>
            <TextArea
              name="Notes"
              placeholder="Reason..."
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="mb-1"
              autoFocus
            />
            <Button className="mt-2" colour="accent" onClick={() => applyOvertime()}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
