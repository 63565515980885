import classNames from "classnames";
import { Argument as ClassNamesArgument } from "classnames";
import React from "react";

import { ColourType, getBgColourClasses } from "./button";

type Props = {
  colour?: ColourType;
  colourOverride?: ClassNamesArgument;
  size?: "sm" | "md" | "lg";
  children?: React.ReactNode;
  className?: string;
} & React.ComponentPropsWithoutRef<"span">;

export const Badge = React.forwardRef<HTMLSpanElement, Props>((props, ref) => {
  let {
    colour = "primary",
    colourOverride,
    className = "", // custom class
    children,
    size = "md",
    ...otherProps
  } = props;

  const backgroundColors = getBgColourClasses({ colour, disabled: false, hover: false });
  const sizeClasses = {
    "px-1 text-xs": size === "sm",
    "px-2 py-1 text-sm": size === "md",
    "px-2 py-1 text-base": size === "lg",
  };

  return (
    <span
      ref={ref}
      {...otherProps}
      className={classNames(
        "rounded-full font-semibold truncate",
        className,
        colourOverride ?? backgroundColors,
        sizeClasses,
      )}
    >
      {children}
    </span>
  );
});

Badge.displayName = "Badge";
