import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { useEmployeeNotesModal } from "helpers/employeeNotes";
import React from "react";
import { useAppSelector } from "store/hooks";
import { selectIsManager, Thread } from "store/reducers";

import { Button } from "./button";
import { EmployeeName } from "./EmployeeName";
import { HoverEmployeeNotesButton } from "./HoverEmployeeNotesButton";

interface CardMessageProps {
  thread: Thread;
  sentByCurrentUser: (thread: Thread) => boolean;
  openThreadModal: (thread: Thread) => void;
}

export function CardMessage({ thread, sentByCurrentUser, openThreadModal }: CardMessageProps) {
  const byCurrentUser = sentByCurrentUser(thread);
  const lastMessage = React.useMemo(() => thread.messages.slice(-1)[0] as Message | undefined, [thread.messages]);
  const { openEmployeeNotesModal } = useEmployeeNotesModal();
  const isManager = useAppSelector(selectIsManager);

  return (
    <div style={{ width: "380px" }} className="rounded overflow-hidden shadow-lg m-4 bg-white relative">
      {!thread.read && !byCurrentUser && (
        <div className="absolute top-0 right-0 m-2">
          <FontAwesomeIcon icon={faEnvelope} className="text-blue-500" />
        </div>
      )}
      <div className="px-6 py-4 break-words">
        <div className="font-bold text-xl mb-2 flex items-center">
          <EmployeeName employeeId={thread.employee_id} />
          {isManager && (
            <HoverEmployeeNotesButton
              employeeId={thread.employee_id}
              onClick={() => openEmployeeNotesModal(thread.employee_id)}
            />
          )}
        </div>
        <p className="text-gray-700 text-base">Last message:</p>
        <p className="text-gray-700 text-base mt-2 truncate">
          {lastMessage && lastMessage.body}
          {!lastMessage && <>Loading...</>}
        </p>
        <div className="flex justify-end">
          <p className="text-sm text-gray-600 mt-2">
            {lastMessage ? format(new Date(lastMessage.when), "MMM d, yyyy, h:mm a") : "Loading..."}
          </p>
        </div>
      </div>
      <div className="px-6 pt-3 pb-2">
        <Button
          onClick={() => openThreadModal(thread)}
          className="flex flex-row items-center"
          colour={!thread.read && !byCurrentUser ? "danger" : "accent"}
        >
          View Messages
        </Button>
      </div>
    </div>
  );
}
