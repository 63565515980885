import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";

import { overtimeApi } from "../overtime/overtimeAPI";
import { shiftApi } from "../shift/shiftApi";

const overtimeAdapter = createEntityAdapter<Overtime>();

const initialState = overtimeAdapter.getInitialState();

const overtimeSlice = createSlice({
  name: "overtime",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(overtimeApi.endpoints.getOvertimes.matchFulfilled, (state, action) => {
      overtimeAdapter.setAll(state, action.payload);
    });
    builder.addMatcher(overtimeApi.endpoints.createOvertime.matchFulfilled, (state, action) => {
      overtimeAdapter.upsertOne(state, action.payload);
    });
    builder.addMatcher(overtimeApi.endpoints.updateOvertime.matchFulfilled, (state, action) => {
      overtimeAdapter.upsertOne(state, action.payload);
    });
    builder.addMatcher(shiftApi.endpoints.fetchWorkedShift.matchFulfilled, (state, action) => {
      overtimeAdapter.upsertMany(state, action.payload.overtimes);
    });
  },
});

export const { selectById: selectOvertimeById, selectAll: selectAllOvertime } = overtimeAdapter.getSelectors<RootState>(
  (state) => state.overtime,
);

export const selectPendingOvertime = createSelector(selectAllOvertime, (overtimes) =>
  overtimes.filter((overtime) => overtime.status === "pending"),
);

export { overtimeSlice };
