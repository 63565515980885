import React from "react";

import { Button } from "../components/button";
import { Modal, ModalProps } from "../components/modal";

type Props = {
  onClickYes: any;
  children?: React.ReactNode;
  autoFocus?: boolean;
} & Omit<ModalProps, "title">;

export function ConfirmationModal(props: Props) {
  const { onClickYes, children, autoFocus = true, ...rest } = props;
  return (
    <Modal
      title="Warning"
      contentLabel="Confirmation Modal"
      buttons={
        <Button size="sm" colour="accent" onClick={onClickYes} autoFocus={autoFocus}>
          Yes
        </Button>
      }
      {...rest}
    >
      <div className="flex flex-col w-64">
        {!children && "Are you sure?"}
        {children}
      </div>
    </Modal>
  );
}
