import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatISO, startOfDay } from "date-fns";
import React from "react";
import { useAppSelector } from "store/hooks";
import { WeekDay, useCreateTravelEventMutation, useUpdateTravelEventMutation } from "store/reducers";
import { selectIsManager } from "store/reducers/config/configSlice";
import { selectAllLocations } from "store/reducers/location/locationSlice";

import { TravelEvent } from "./TravelEvent";
import { Button } from "../components/button";
import { IconButton } from "../components/iconButton";
import { Modal, ModalProps } from "../components/modal";
import { SelectDropDown } from "../components/selectDropDown";

type Props = {
  employee: Employee;
  day: WeekDay;
  dismissModal: any;
  isOpen: boolean;
} & Omit<ModalProps, "title">;

export function TravelEventsModal(props: Props) {
  const { employee, day, ...rest } = props;
  const isManager = useAppSelector(selectIsManager);
  const mriLocations = useAppSelector(selectAllLocations);
  const [isAddingNew, setIsAddingNew] = React.useState<boolean>(false);
  const [toLocationId, setToLocationId] = React.useState<number>();
  const [createTravelEvent] = useCreateTravelEventMutation();
  const [updateTravelEvent] = useUpdateTravelEventMutation();

  function onArchiveTravelEvent(travel_event_id: number) {
    // This actually archives it setting `archived` to true
    updateTravelEvent({
      travelEventId: travel_event_id,
    });
  }

  function addNewTravelEvent() {
    setIsAddingNew(true);
  }

  function onChangeToLocation(e: React.ChangeEvent<HTMLSelectElement>) {
    setToLocationId(Number(e.target.value));
  }

  async function onSaveTravelEvent() {
    if (!toLocationId) return;
    await createTravelEvent({
      employee_id: employee.id,
      travel_to_location_id: toLocationId,
      travel_start_time: formatISO(startOfDay(new Date(day.date))),
    });
    setIsAddingNew(false);
  }

  return (
    <Modal title={"Travel Log"} {...rest}>
      <table className="table">
        <thead>
          <tr>
            <th className="table-header-column-name">Name</th>
            <th className="table-header-column-name">To</th>
            <th className="table-header-column-name">Travel Start Time</th>
            {isManager && <th className="table-header-column-name">Action</th>}
          </tr>
        </thead>
        <tbody>
          {day.travel_event_ids.map((travelEventId) => {
            return (
              <TravelEvent
                key={travelEventId}
                travelEventId={travelEventId}
                onArchiveTravelEvent={onArchiveTravelEvent}
              />
            );
          })}
          {isAddingNew && (
            <tr>
              <td className="table-body-cell">
                <p className="whitespace-nowrap">{employee.name}</p>
              </td>
              <td className="table-body-cell">
                <div className="relative">
                  <SelectDropDown value={toLocationId} onChange={onChangeToLocation}>
                    {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                    {mriLocations && <option value="">Unspecified</option>}
                    {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                    {mriLocations ? (
                      mriLocations.map((location) => (
                        <option key={location.id} value={location.id}>
                          {location.name}
                        </option>
                      ))
                    ) : (
                      <option>Loading...</option>
                    )}
                  </SelectDropDown>
                </div>
              </td>
              <td className="table-body-cell">-</td>
              <td className="table-body-cell">
                <IconButton
                  icon="save"
                  title="Save"
                  colour="accent"
                  onClick={onSaveTravelEvent}
                  disabled={!toLocationId}
                />
              </td>
            </tr>
          )}
          {isManager && !isAddingNew && (
            <tr>
              <td className="whitespace-nowrap" colSpan={5}>
                <Button colour="none" textColour="text-accent" onClick={() => addNewTravelEvent()}>
                  <FontAwesomeIcon icon="plus" /> Add a new travel event
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Modal>
  );
}
