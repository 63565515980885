import React from "react";

import { UnavailableEventModal } from "./unavailable_event_modal";

type Props = {
  employees: Employee[];
};

export function NewUnavailableEvent(props: Props) {
  const { employees } = props;
  const [unavailableEventModal, setUnavailableEventModal] = React.useState<React.ReactNode>(undefined);

  function openUnavailableEventModal() {
    setUnavailableEventModal(
      <UnavailableEventModal dismissModal={() => setUnavailableEventModal(undefined)} employees={employees} />,
    );
  }

  return (
    <div className="bg-background">
      <span className="py-2 mr-4 mt-2 text-xl font-semibold text-primary">Availability</span>
      <button
        onClick={() => openUnavailableEventModal()}
        className="bg-accent hover:bg-accent text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring"
      >
        New Unavailable Event
      </button>
      {unavailableEventModal}
    </div>
  );
}
