import Tippy from "@tippyjs/react";
import { addMinutes, addSeconds, differenceInMinutes } from "date-fns";
import React from "react";

type Props = {
  seconds: number;
  showZeroHours?: boolean;
};

const Tooltip = (props: { hoursFloat: string; copied: boolean }) => {
  let { hoursFloat, copied } = props;
  return (
    <div>
      {!copied && <span>{hoursFloat} hours</span>}
      {copied && <span className="text-sm"> copied</span>}
    </div>
  );
};

/**
 * Format a duration in seconds as hours and minutes.
 * e.g. 3660 seconds -> 1h 01m
 */
function formatDuration(seconds: number) {
  let date = new Date(0);
  let zero = addMinutes(date, date.getTimezoneOffset());
  let duration = addSeconds(zero, seconds);

  let totalMinutes = Math.abs(differenceInMinutes(duration, zero));
  let hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes % 60;
  let hoursFloat = (totalMinutes / 60).toFixed(2);

  // Preserve negative sign if seconds were negative
  if (seconds < 0) {
    hours = -hours;
    minutes = -minutes;
    hoursFloat = "-" + hoursFloat;
  }

  return { hours, minutes, hoursFloat };
}

export const Duration = React.forwardRef<HTMLSpanElement, Props>((props: Props, ref) => {
  let { seconds, showZeroHours = true } = props;

  const { hours, minutes, hoursFloat } = formatDuration(seconds);

  const [copied, setCopied] = React.useState(false);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const onClick = () => {
    // Copy the duration to the clipboard
    navigator.clipboard.writeText(hoursFloat);
    // Show a "copied" tooltip
    setCopied(true);

    // Clear any existing timeout before setting a new one
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => setCopied(false), 2000);
  };

  const onHidden = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setCopied(false);
  };

  const tooltip = <Tooltip hoursFloat={hoursFloat} copied={copied} />;

  return (
    <Tippy hideOnClick={false} onHidden={onHidden} content={tooltip}>
      <span ref={ref} onClick={onClick} className="underline underline-offset-2 cursor-pointer">
        {showZeroHours && <>{hours}h </>}
        {!showZeroHours && hours > 0 && <>{hours}h </>}
        {minutes.toLocaleString("en-AU", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
        m
      </span>
    </Tippy>
  );
});

Duration.displayName = "Duration";
