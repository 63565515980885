import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { payrollRoleOptions, EmployeeProfile } from "components/EmployeeProfile";
import { HoverEmployeeNotesButton } from "components/HoverEmployeeNotesButton";
import { format } from "date-fns";
import { useEmployeeNotesModal } from "helpers/employeeNotes";
import React from "react";
import { useAppSelector } from "store/hooks";
import { selectAllRrUsers } from "store/reducers";
import { useFetchOrganisationsQuery } from "store/reducers/organisation";

import { EmployeeLink } from "./EmployeeLink";
import { Button } from "../components/button";
import { Modal } from "../components/modal";

type Props = {
  employee: Employee;
  employees: Employee[];
};

export function EmployeeEdit(props: Props) {
  const rrUsers = useAppSelector(selectAllRrUsers);

  const [isEditing, setEditing] = React.useState(false);
  let { employee, employees } = props;

  const { openEmployeeNotesModal } = useEmployeeNotesModal();

  const { data: orgStore } = useFetchOrganisationsQuery();

  const rrUser = rrUsers.find((u) => u.id === employee.radreport_user_id);
  const startDate = employee.xero_StartDate && new Date(employee.xero_StartDate);
  const terminationDate = employee.xero_TerminationDate && new Date(employee.xero_TerminationDate);

  const payrollRole = payrollRoleOptions.find((r) => r.value === employee.payroll_role);

  let org = orgStore?.organisations.find((o) => o.xero_tenantId === employee.xero_tenantId);

  function cancel() {
    setEditing(false);
  }

  return (
    <div className="my-2">
      <div>
        <div
          title={`${employee.name} [${employee.id}] ${employee.xero_Email}`}
          className="font-semibold flex items-center"
        >
          {employee.name}
          <span className="ml-1">
            <EmployeeLink employee={employee} />
          </span>
          <HoverEmployeeNotesButton employeeId={employee.id} onClick={() => openEmployeeNotesModal(employee.id)} />
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {rrUsers && rrUser && (
          <div title={`${rrUser.name}. ${rrUser.username}. [${rrUser.id}]`}>
            <FontAwesomeIcon icon="check" className="text-success" /> RadReport linked
          </div>
        )}
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {rrUsers && !rrUser && (
          <div className="text-danger">
            <FontAwesomeIcon icon="exclamation" className="text-danger" /> RadReport not linked
          </div>
        )}
        <div>{org?.xero_tenantName || "No organisation"}</div>
        {startDate && <div>Started {format(startDate, "dd/MM/yyyy")}</div>}
        {terminationDate && <div>Ended {format(terminationDate, "dd/MM/yyyy")}</div>}
        {payrollRole && <div>{payrollRole.label}</div>}
      </div>

      {isEditing && (
        <Modal isOpen dismissModal={cancel} title="Edit Employee">
          <EmployeeProfile employeeId={employee.id} onCancel={cancel} />
        </Modal>
      )}

      <div
        className="grid gap-1 pt-1"
        style={{
          gridAutoColumns: "min-content",
          gridTemplateColumns: "repeat(auto-fit, 100px)",
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {employee && <Button onClick={() => setEditing(true)}>Edit</Button>}
      </div>
    </div>
  );
}
