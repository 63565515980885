import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Portal } from "@radix-ui/react-portal";
import { addDays, format, formatISO, isBefore, set } from "date-fns";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { useAppSelector } from "store/hooks";
import { FetchAllRosteredShiftsResponse, rosterApi, useFetchEmployeesQuery } from "store/reducers";
import { useGetConfigQuery } from "store/reducers/config/configAPI";
import { selectIsManager } from "store/reducers/config/configSlice";
import { selectAllDepartments } from "store/reducers/department/departmentSlice";
import { selectAllLocations } from "store/reducers/location/locationSlice";
import { selectAllRrUsers } from "store/reducers/rrUsers/rrUsersSlice";

import { OpenNewShiftModalFunction } from "./RosterComponent";
import { LocationDropdown } from "../attendance/LocationDropdown";
import { Button } from "../components/button";
import { Checkbox } from "../components/Checkbox";
import { EmployeeComboBox } from "../components/employeeComboBox";
import { Modal, ModalProps } from "../components/modal";
import { SelectDropDown } from "../components/selectDropDown";
import { TextArea } from "../components/textarea";
import { MODALITIES } from "../constants";
import { ROLE_FILTERS } from "../timesheet_viewer/employee_filter";
import { TimeInput } from "../timesheet_viewer/table_components/time_input";

function defaultStart(d: Date) {
  return set(d, { hours: 9, minutes: 0, seconds: 0 });
}
function defaultEnd(d: Date) {
  return set(d, { hours: 17, minutes: 0, seconds: 0 });
}
function defaultBreakStart(d: Date) {
  return set(d, { hours: 13, minutes: 0, seconds: 0 });
}
function defaultBreakEnd(d: Date) {
  return set(d, { hours: 13, minutes: 30, seconds: 0 });
}

type Props = {
  openNewShiftModal: OpenNewShiftModalFunction;
  addShiftDate: Date | null;
  addShiftEmployee: Employee | null;
  rosteredShift?: RosteredShift;
  rosterFetchAllResponse: FetchAllRosteredShiftsResponse;
} & Omit<ModalProps, "title" | "isOpen">;

type ModalitySelect = {
  label: string;
  value: string;
};

export function RosterNewShiftModal(props: Props) {
  const { openNewShiftModal, addShiftDate, addShiftEmployee, rosteredShift, rosterFetchAllResponse, ...rest } = props;

  const rrUsers = useAppSelector(selectAllRrUsers);
  const departments = useAppSelector(selectAllDepartments);
  useGetConfigQuery();
  const isManager = useAppSelector(selectIsManager);
  const mriLocations = useAppSelector(selectAllLocations);

  const [createRosteredShift, { isLoading: isCreatingRosteredShift }] = rosterApi.useCreateRosteredShiftMutation();
  const [deleteRosteredShift, { isLoading: isDeletingRosteredShift }] = rosterApi.useDeleteRosteredShiftMutation();
  const [updateRosteredShift, { isLoading: isUpdatingRosteredShift }] = rosterApi.useUpdateRosteredShiftMutation();
  const [prefillRow, { isLoading: isPrefillingRow }] = rosterApi.usePrefillRowMutation();

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(addShiftDate);

  const { data: employees } = useFetchEmployeesQuery({
    include_not_linked_to_xero: true,
    // Specifying the date excludes terminated employees
    date: selectedDate ? formatISO(selectedDate, { representation: "date" }) : undefined,
  });
  const [selectedEmployee, setSelectedEmployee] = React.useState<Employee | null | undefined>(addShiftEmployee);
  const selectedRRUser = rrUsers.find((u) => u.id === selectedEmployee?.radreport_user_id);

  const [startTime, setStartTime] = React.useState<Date | null>(
    // When updating exist rostered shift
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (rosteredShift?.start && new Date(rosteredShift.start)) ||
      // When creating new rostered shift
      (addShiftDate && defaultStart(addShiftDate)),
  );
  const [endTime, setEndTime] = React.useState<Date | null>(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (rosteredShift?.end && new Date(rosteredShift.end)) || (addShiftDate && defaultEnd(addShiftDate)),
  );
  const [breakStartTime, setBreakStartTime] = React.useState<Date | null>(
    // When updating exist rostered shift
    (rosteredShift?.rostered_breaks[0]?.start &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      new Date(rosteredShift.rostered_breaks[0].start)) ||
      // When creating new rostered shift
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (!rosteredShift && addShiftDate && defaultBreakStart(addShiftDate)) ||
      null,
  );
  const [breakEndTime, setBreakEndTime] = React.useState<Date | null>(
    (rosteredShift?.rostered_breaks[0]?.end &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      new Date(rosteredShift.rostered_breaks[0].end)) ||
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (!rosteredShift && addShiftDate && defaultBreakEnd(addShiftDate)) ||
      null,
  );
  const [department, setDepartment] = React.useState<any>(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (rosteredShift?.department && rosteredShift.department) ||
      (selectedEmployee && defaultDepartment()) ||
      departments[0],
  );
  const [location, setLocation] = React.useState<LocationSchema>(
    // @ts-expect-error `mriLocations.length > 0` so it can't be undefined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (rosteredShift?.location && rosteredShift.location) || mriLocations[0],
  );
  const [isPrefillChecked, setIsPrefillChecked] = React.useState<boolean>(false);
  const [notes, setNotes] = React.useState<string>(rosteredShift?.notes || "");

  const [modalities, setModalities] = React.useState<ModalitySelect[]>(
    rosteredShift?.modalities.map((m) => ({
      label: m,
      value: m,
    })) || [],
  );

  // When the selectedDate changes, correct the start/end time inputs
  React.useEffect(() => {
    if (selectedDate && !rosteredShift) {
      const toSelectedDate = (d: Date | null) => {
        if (d === null) return d;
        return set(d, {
          year: selectedDate.getFullYear(),
          month: selectedDate.getMonth(),
          date: selectedDate.getDate(),
        });
      };
      setStartTime((t) => toSelectedDate(t));
      setEndTime((t) => toSelectedDate(t));
      setBreakStartTime((t) => toSelectedDate(t));
      setBreakEndTime((t) => toSelectedDate(t));
    }
  }, [selectedDate, rosteredShift]);

  const isTimeValid = startTime && endTime ? isBefore(startTime, endTime) : null;
  const isBreakValid =
    (breakStartTime &&
      breakEndTime &&
      isBefore(breakStartTime, breakEndTime) &&
      (breakStartTime as Date).getTime() >= (startTime as Date).getTime() &&
      (breakEndTime as Date).getTime() <= (endTime as Date).getTime()) ||
    (breakStartTime === null && breakEndTime === null);

  const [datePickerIsOpen, setDatePickerIsOpen] = React.useState(false);
  const filteredEmployee =
    // slice() because sort() will mutate the store
    employees?.employees.slice().sort(
      // Show not added first
      (e1, e2) => Number(isEmployeeAdded(e1.id)) - Number(isEmployeeAdded(e2.id)),
    ) ?? [];

  function isEmployeeAdded(employee_id: number) {
    let index = rosterFetchAllResponse.all_employee_rostered_shifts.findIndex(
      (rosterData) => rosterData.employee_id === employee_id,
    );
    return index !== -1;
  }

  async function saveNewRosteredShift(_e: React.MouseEvent, isSaveAndCreate: boolean) {
    function onSuccess() {
      if (isSaveAndCreate) {
        props.dismissModal();
        openNewShiftModal({
          date: addShiftDate,
          employee: selectedEmployee ?? null,
          rosteredShift: undefined,
        });
      } else {
        props.dismissModal();
      }
    }
    let rostered_break: RosteredBreak | null;
    if (breakStartTime && breakEndTime) {
      rostered_break = {
        start: formatISO(breakStartTime, { representation: "complete" }),
        end: formatISO(breakEndTime, { representation: "complete" }),
      };
    } else {
      rostered_break = null;
    }

    if (!isTimeValid || !isBreakValid) {
      return;
    }

    if (rosteredShift && selectedEmployee && startTime && endTime) {
      await updateRosteredShift({
        rosteredShiftId: rosteredShift.id,
        body: {
          start: formatISO(startTime, { representation: "complete" }),
          end: formatISO(endTime, { representation: "complete" }),
          department_id: department.id,
          location_id: location.id,
          notes: notes,
          rostered_break,
          modalities: modalities.map((m) => m.value),
        },
      });
      onSuccess();
    } else if (!rosteredShift && selectedEmployee && startTime && endTime) {
      // Adding new employee or create a new roster shift
      if (isPrefillChecked) {
        await prefillRow({
          body: {
            employee_id: selectedEmployee.id,
            location_id: location.id,
            start: formatISO(startTime, { representation: "complete" }),
            end: formatISO(endTime, { representation: "complete" }),
            department_id: department.id,
            notes: notes,
            rostered_break,
            modalities: modalities.map((m) => m.value),
          },
        }).unwrap();
        onSuccess();
      } else {
        let responseData = await createRosteredShift({
          body: {
            employee_id: selectedEmployee.id,
            location_id: location.id,
            start: formatISO(startTime, { representation: "complete" }),
            end: formatISO(endTime, { representation: "complete" }),
            department_id: department.id,
            notes: notes,
            rostered_break,
            modalities: modalities.map((m) => m.value),
          },
        }).unwrap();

        // TODO(store): Broken for scrolling to the new row because data is loaded separately with `rosterApi.useFetchAllQuery` in RosterComponent
        // requestAnimationFrame(() => {
        //   const el = document.querySelector(
        //     `.${ROSTER_SHIFT_ROW_CLASS}[data-employee_id="${selectedEmployee.id}"]`,
        //   );
        //   if (el) {
        //     el.scrollIntoView();
        //   }
        // });
        onSuccess();
      }
    }
  }

  function addOneMoreShift() {
    //Open New Modal
    props.dismissModal();
    openNewShiftModal({
      date: addShiftDate,
      employee: selectedEmployee ?? null,
      rosteredShift: undefined,
    });
  }

  function addBreak() {
    if (addShiftDate) {
      setBreakStartTime(defaultBreakStart(addShiftDate));
      setBreakEndTime(defaultBreakEnd(addShiftDate));
    }
  }

  function deleteBreak() {
    setBreakStartTime(null);
    setBreakEndTime(null);
  }

  async function removeRosteredShift(rosteredShift: RosteredShift) {
    await deleteRosteredShift({
      rosteredShiftId: rosteredShift.id,
      employeeId: rosteredShift.employee_id,
    });
    props.dismissModal();
  }

  let onChangeDepartment: React.ComponentProps<"select">["onChange"] = function (e) {
    let department = departments.find((department) => department.name === e.target.value);
    setDepartment(department);
  };

  function defaultDepartment() {
    // This function is for auto selecting the department to make it same as the company role in the modal
    let role_filter = ROLE_FILTERS.find((f) =>
      selectedRRUser?.company_roles.some((companyRoleId) => f.includesCompanyRole(companyRoleId)),
    );

    if (role_filter) {
      return departments.find((department) => department.name === role_filter?.label);
    }
    return undefined;
  }

  let onChangeLocation: React.ComponentProps<"select">["onChange"] = function (e) {
    let location = mriLocations.find((site) => String(site.id) === e.target.value);
    if (!location) {
      throw new Error("Location not found");
    }
    setLocation(location);
  };

  function onChangeNewEmployeeShiftDate(date: Date) {
    setSelectedDate(date);
  }

  function toggleDatePicker() {
    setDatePickerIsOpen(!datePickerIsOpen);
  }

  function onChangeModalities(value: ModalitySelect[]) {
    setModalities(value);
  }

  let modalTitle = "Rostered Shift";

  if (rosteredShift && isManager) {
    modalTitle = "Edit Rostered Shift";
  } else if (!rosteredShift && selectedEmployee && isManager) {
    modalTitle = "Add New Roster Shift";
  } else if (!rosteredShift && !selectedEmployee && isManager) {
    modalTitle = "Add New Employee Row and Prefill Shifts";
  }

  return (
    <Modal isOpen title={modalTitle} showBottomCloseButton={false} {...rest}>
      <div className="w-full rounded-lg bg-white">
        <div className="flex flex-col">
          <div className="md:flex mb-6">
            <div className="md:w-1/2 mb-6 md:mb-0">
              {rosteredShift && (
                <>
                  <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                    Employee Name
                  </label>
                  <div className="flex">
                    <span className="text-primary-dark">{selectedEmployee?.name}</span>
                  </div>
                </>
              )}
              {!rosteredShift && (
                <EmployeeComboBox
                  defaultValue={selectedEmployee}
                  employees={filteredEmployee}
                  onSelectEmployee={(employee) => setSelectedEmployee(employee)}
                />
              )}
            </div>
            <div className="md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Date</label>
              <div className="flex">
                {rosteredShift && addShiftDate && (
                  <span className="text-primary-dark">{format(addShiftDate, "EEE dd MMM")}</span>
                )}
                {!rosteredShift && addShiftDate && (
                  <>
                    <DatePicker
                      className={
                        "appearance-none block w-full bg-background text-grey-darker border border-primary-light rounded-l py-3 px-4 mb-3 focus:outline-none cursor-not-allowed"
                      }
                      selected={selectedDate}
                      onChange={onChangeNewEmployeeShiftDate}
                      open={datePickerIsOpen}
                      onSelect={toggleDatePicker}
                      dateFormat="dd/MM/yyyy"
                      // Append to document.body
                      popperContainer={Portal}
                      minDate={addShiftDate}
                      maxDate={addDays(addShiftDate, 6)}
                      readOnly
                      disabledKeyboardNavigation
                    />
                    <Button
                      onClick={toggleDatePicker}
                      colour="accent"
                      className="mb-3 rounded-r rounded-l-none py-2 px-4"
                    >
                      <FontAwesomeIcon icon="calendar-alt" />
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="md:flex mb-6">
            <div className="md:w-1/3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Start</label>
              {isManager && <TimeInput type="roster" onEnter={(time) => setStartTime(time)} initialValue={startTime} />}

              {!isManager && startTime && format(new Date(startTime), "h:mm aaaa")}
            </div>
            <div className="md:w-1/3 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Finish</label>
              {isManager && <TimeInput type="roster" onEnter={(time) => setEndTime(time)} initialValue={endTime} />}
              {!isManager && endTime && format(new Date(endTime), "h:mm aaaa")}
            </div>
            {breakStartTime !== null && breakEndTime !== null && (
              <>
                <div className="md:w-1/4 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                    Break Start
                  </label>
                  {isManager && (
                    <TimeInput type="break" onEnter={(time) => setBreakStartTime(time)} initialValue={breakStartTime} />
                  )}
                  {!isManager &&
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    breakStartTime &&
                    format(new Date(breakStartTime), "h:mm aaaa")}
                </div>
                <div className="md:w-1/4 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-xs mb-2 font-bold text-grey-darker">
                    Break End
                  </label>
                  {isManager && (
                    <TimeInput type="break" onEnter={(time) => setBreakEndTime(time)} initialValue={breakEndTime} />
                  )}
                  {!isManager &&
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    breakEndTime &&
                    format(new Date(breakEndTime), "h:mm aaaa")}
                </div>
              </>
            )}

            <div className="md:w-1/4 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-xs mb-2 font-bold text-grey-darker">
                Break option
              </label>
              {isManager && breakStartTime && breakEndTime && (
                <button className="my-3" onClick={() => deleteBreak()}>
                  Delete break
                </button>
              )}
              {isManager && addShiftDate && breakStartTime === null && breakEndTime === null && (
                <button className="my-3" onClick={() => addBreak()}>
                  Add break
                </button>
              )}
            </div>
          </div>

          <div className="md:flex mb-6">
            <div className="md:w-1/2 mb-6 md:mb-0 w-full">
              {isManager && (
                <SelectDropDown showLabel="Department" value={department.name} onChange={onChangeDepartment}>
                  {departments.map((department) => {
                    return <option key={department.id}>{department.name}</option>;
                  })}
                </SelectDropDown>
              )}
              {!isManager && (
                <>
                  <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                    Department
                  </label>
                  {department.name}
                </>
              )}
            </div>
            <div className="md:w-1/2 px-3 mb-6 md:mb-0 w-full">
              {isManager && (
                <LocationDropdown
                  onChange={onChangeLocation}
                  showDeleted={false}
                  showUnspecified={false}
                  showLabel="Location"
                  value={location.id || ""}
                />
              )}

              {!isManager && (
                <>
                  <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                    Location
                  </label>
                  {location.name}
                </>
              )}
            </div>
          </div>

          <div className="md:flex flex-col mb-6">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Modalities</label>
            {isManager && (
              <Select
                className="w-full"
                closeMenuOnSelect={false}
                menuPortalTarget={document.body}
                isMulti
                options={MODALITIES.map((m) => ({ label: m, value: m }))}
                value={modalities}
                onChange={(value) =>
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  onChangeModalities((value as ModalitySelect[]) || [])
                }
                placeholder={<>Select modalities&hellip;</>}
              />
            )}
            {!isManager && <>{modalities.map((m) => m.value).join(", ")}</>}
          </div>

          {!rosteredShift && isManager && (
            <div className="md:flex mb-6">
              <div className="mb-6 md:mb-0">
                <Checkbox onCheckedChange={(e) => setIsPrefillChecked(Boolean(e))}>
                  Prefill this shift for the rest of the working days
                </Checkbox>
              </div>
            </div>
          )}

          <div className="space-y-4 my-2">
            {!isTimeValid && (
              <div>
                <span className="text-white rounded p-2 bg-danger">
                  Error: Something wrong with the start/end time!
                </span>
              </div>
            )}
            {!isBreakValid && (
              <div>
                <span className="text-white rounded p-2 bg-danger">
                  Error: Something wrong with the break start/end time!
                </span>
              </div>
            )}
          </div>

          <div className="md:flex mb-6">
            <div className="md:w-full">
              <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">Notes</label>
              {isManager && (
                <TextArea
                  name="Notes"
                  placeholder="Message..."
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  maxLength={100}
                />
              )}
              {!isManager && notes}
            </div>
          </div>
          {rosteredShift && isManager && (
            <div className="md:flex mb-6">
              <div className="mb-6 md:mb-0">
                <Button size="sm" colour="danger" onClick={() => removeRosteredShift(rosteredShift)}>
                  <FontAwesomeIcon icon="trash-alt" /> Remove shift
                </Button>
              </div>
            </div>
          )}
        </div>

        {isManager && (
          <div className="px-5 py-4 flex justify-between">
            {rosteredShift && (
              <button
                className="bg-none text-accent font-bold hover:text-accent-dark"
                onClick={() => addOneMoreShift()}
              >
                + Add one more shift
              </button>
            )}

            <div className="ml-auto">
              <button
                onClick={props.dismissModal}
                className="text-sm py-2 px-3 text-primary hover:text-primary-dark transition duration-150"
              >
                Close
              </button>
              <button
                onClick={(e) => saveNewRosteredShift(e, true)}
                className={
                  "text-sm py-2 px-3 text-white bg-accent hover:bg-accent-dark transition duration-150 font-bold rounded focus:outline-none focus:ring"
                }
              >
                Save and create new
              </button>
              <button
                onClick={(e) => saveNewRosteredShift(e, false)}
                className={
                  "text-sm py-2 px-3 ml-2 text-white bg-accent hover:bg-accent-dark transition duration-150 font-bold rounded focus:outline-none focus:ring"
                }
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
