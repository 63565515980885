import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { EmployeeName } from "components/EmployeeName";
import { HoverEmployeeNotesButton } from "components/HoverEmployeeNotesButton";
import { MessageAcknowledgement } from "components/MessageAcknowledgement";
import { PayrollInquire } from "components/PayrollInquire";
import { format } from "date-fns";
import { useEmployeeNotesModal } from "helpers/employeeNotes";
import { filteredDateRoster, formatRoster } from "helpers/messages";
import React, { useEffect, useRef, useState } from "react";
import { RosterNewShiftModal } from "roster/roster_new_shift_modal";
import { OpenNewShiftModalFunction } from "roster/RosterComponent";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  FetchAllRosteredShiftsResponse,
  selectEmployeeById,
  selectIsManager,
  selectRosterByEmployeeId,
  selectRosteredShiftEntities,
  useGetConfigQuery,
} from "store/reducers";
import { readThread, sendMessage, acknowledgeMessage } from "store/reducers/message/messageAPI";
import { Thread, selectThreads } from "store/reducers/message/messageSlice";
import { messageOptions } from "utils/message_options";

import { Button } from "../components/button";
import { Modal, ModalProps } from "../components/modal";
import { SelectDropDown } from "../components/selectDropDown";
import { TextArea } from "../components/textarea";

type ThreadModalProps = {
  thread: Thread;
  rosterFetchAllResponse: FetchAllRosteredShiftsResponse;
  managementMode: boolean;
} & Omit<ModalProps, "title">;

export function ThreadModal(props: ThreadModalProps) {
  const dispatch = useAppDispatch();

  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const { thread, rosterFetchAllResponse, managementMode, ...rest } = props;
  const [newMessage, setNewMessage] = React.useState("");
  const [selectedRosterId, setSelectedRosterId] = React.useState<number | undefined>(undefined);
  const [rosterModal, setRosterModal] = React.useState<React.ReactNode>();
  const [filterMessageType, setFilterMessageType] = React.useState("");
  const [messageAcknowledgement, setMessageAcknowledgement] = React.useState(false);

  const [payPeriodEndDate, setPayPeriodEndDate] = React.useState<Date | null>(null);

  const [tempAttachment, setTempAttachment] = useState<Upload | undefined>(undefined);

  const { data: configData } = useGetConfigQuery();

  const threads = useAppSelector(selectThreads);

  const currentThread = threads.find((t) => t.employee_id === thread.employee_id);

  const lastMessage = currentThread?.messages[currentThread.messages.length - 1];

  const defaultMessageType = messageOptions[0];

  const sentByCurrentUser = lastMessage?.author_id === configData?.currentEmployee?.id;

  const isManager = useAppSelector(selectIsManager);

  const { openEmployeeNotesModal } = useEmployeeNotesModal();

  useEffect(() => {
    /*
      When a thread is opened, a new message is added or filtered by type,
      the view automatically scrolls to the bottom of the message list.
    */
    requestAnimationFrame(() => {
      const container = messagesContainerRef.current;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    });
  }, [currentThread?.messages, filterMessageType]);

  useEffect(() => {
    // Automatically mark the thread as read when the modal opens
    if (configData?.currentEmployee && thread.messages.length > 0) {
      dispatch(
        readThread({
          employee_id: thread.employee_id,
          currentEmployeeId: configData.currentEmployee.id,
        }),
      );
    }
  }, [dispatch, thread, configData]);

  // get the message type from the last message sent
  const findMessageOption = (type: string) => {
    return messageOptions.find((option) => option.value === type) || defaultMessageType;
  };

  const [messageType, setMessageType] = React.useState(() => {
    if (sentByCurrentUser && lastMessage) {
      return findMessageOption(lastMessage.type);
    }
    return defaultMessageType;
  });

  const rosteredShiftEntities = useAppSelector(selectRosteredShiftEntities);

  const employeeRoster = useAppSelector((state) => selectRosterByEmployeeId(state, configData!.currentEmployee!.id));

  const filteredRoster = filteredDateRoster(employeeRoster);

  const employee = useAppSelector((state) => selectEmployeeById(state, thread.employee_id));

  function handleSendMessage() {
    const data = {
      body: newMessage,
      employee_id: thread.employee_id,
      rostered_shift_id: selectedRosterId,
      type: messageType.value,
      requires_ack: messageAcknowledgement,
      pay_period_ending: payPeriodEndDate ? format(new Date(payPeriodEndDate), "yyyy-MM-dd") : null,
      attachment_filename: tempAttachment ? tempAttachment.filename : null,
    };

    if (configData?.currentEmployee?.id) {
      dispatch(
        sendMessage({
          data,
          currentEmployeeId: configData.currentEmployee.id,
        }),
      );
    }
    setNewMessage("");
    setTempAttachment(undefined);
    setPayPeriodEndDate(null);
    setMessageAcknowledgement(false);
    setSelectedRosterId(undefined);
  }

  function handleAcknowledgeMessage(messageId: number) {
    if (configData?.currentEmployee) {
      dispatch(
        acknowledgeMessage({
          messageId,
        }),
      );
    }
  }

  let openNewShiftModal: OpenNewShiftModalFunction = (options: {
    date: Date | null;
    employee: Employee | null;
    rosteredShift?: RosteredShift;
  }) => {
    const { date, employee, rosteredShift } = options;

    setRosterModal(
      <RosterNewShiftModal
        dismissModal={() => setRosterModal(undefined)}
        openNewShiftModal={openNewShiftModal}
        addShiftDate={date}
        addShiftEmployee={employee}
        rosteredShift={rosteredShift}
        rosterFetchAllResponse={rosterFetchAllResponse}
      />,
    );
  };

  function anonymiseManagerName(message: Message, currentThread: Thread, isManager: boolean) {
    if (message.author_id === currentThread.employee_id) {
      return <EmployeeName employeeId={message.author_id} />;
    } else if (!isManager) {
      return "Manager";
    } else {
      return <EmployeeName employeeId={message.author_id} />;
    }
  }

  return (
    <>
      <Modal
        title={
          <>
            Thread with <EmployeeName employeeId={thread.employee_id} />
            {isManager && (
              <HoverEmployeeNotesButton
                // @ts-expect-error TS18048
                employeeId={employee.id}
                // @ts-expect-error TS18048
                onClick={() => openEmployeeNotesModal(employee.id)}
              />
            )}
          </>
        }
        {...rest}
      >
        {managementMode && (
          <div className="mb-5 max-w-48">
            <SelectDropDown
              showLabel="Filter by Message Type:"
              value={filterMessageType}
              onChange={(e) => setFilterMessageType(e.target.value)}
            >
              <option value="">All Messages</option>
              {messageOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </SelectDropDown>
          </div>
        )}
        <div ref={messagesContainerRef} style={{ maxHeight: "300px", overflowY: "auto" }} className="break-words">
          <ul className="list-none p-0 flex flex-col">
            {currentThread?.messages
              .filter((message: Message) => filterMessageType === "" || message.type === filterMessageType)
              .map((message) => {
                const rosteredShift = message.rostered_shift_id
                  ? rosteredShiftEntities[message.rostered_shift_id]
                  : null;

                const messageTypeLabel = messageOptions.find((option) => option.value === message.type)?.label;

                return (
                  <li
                    key={message.id}
                    className={classNames("p-2 rounded-md mb-2 max-w-96", {
                      "bg-accent-lightest self-end": message.author_id === currentThread.employee_id,
                      "bg-primary-light self-start": message.author_id !== currentThread.employee_id,
                    })}
                  >
                    <div className="font-bold">{anonymiseManagerName(message, currentThread, isManager)}</div>
                    <div className="whitespace-pre-line">{message.body}</div>
                    <div className="text-sm">{format(new Date(message.when), "MMM d, yyyy, h:mm a")}</div>
                    {message.type === "payroll" && (
                      <div>
                        <div className="text-sm">
                          <strong>Pay Period End Date: </strong>
                          {format(new Date(message.pay_period_ending), "dd-MM-yyyy")}
                        </div>

                        {message.attachment_filename && (
                          <a href={"/api/upload/" + message.attachment_filename} target="_blank" rel="noreferrer">
                            <Button size="sm" className="mt-2">
                              <FontAwesomeIcon icon="paperclip" />
                              Attachment
                            </Button>
                          </a>
                        )}
                      </div>
                    )}

                    <div className="text-sm">Type: {messageTypeLabel}</div>

                    {rosteredShift && (
                      <>
                        <div className="text-sm">
                          <strong>Shift: </strong>
                          {formatRoster(
                            rosteredShift.start,
                            rosteredShift.end,
                            rosteredShift.location,
                            rosteredShift.department,
                          )}
                        </div>
                        <Button
                          onClick={() =>
                            openNewShiftModal({
                              date: new Date(rosteredShift.start),
                              // @ts-expect-error TS2322
                              employee: employee,
                              rosteredShift: rosteredShift,
                            })
                          }
                          size="sm"
                          colour="accent"
                        >
                          Edit Shift
                        </Button>
                        <a
                          href={`/roster?shift=${rosteredShift.id}&s=${rosteredShift.start.split("T")[0]}`}
                          target="_blank"
                          className="text-sm ml-2"
                        >
                          Open Shift
                        </a>
                      </>
                    )}
                    <MessageAcknowledgement
                      message={message}
                      sentByCurrentUser={sentByCurrentUser}
                      handleAcknowledgeMessage={handleAcknowledgeMessage}
                    />
                  </li>
                );
              })}
          </ul>
        </div>

        {currentThread?.read && (
          <span>
            <i>Read</i>
          </span>
        )}

        {!sentByCurrentUser &&
          configData?.currentEmployee?.id === currentThread?.employee_id &&
          currentThread?.requires_ack && (
            <div className="text-danger mt-2">
              <FontAwesomeIcon icon="exclamation-circle" className="text-accent" />
              <span className="ml-1">
                You have messages awaiting acknowledgement. Please click the Acknowledge Message button within the
                respective message to acknowledge receipt.
              </span>
            </div>
          )}

        <div className="flex flex-col mt-3">
          {messageType.value !== "payroll" && (
            <TextArea
              name="Body"
              placeholder="Message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              maxLength={500}
              autoFocus
            />
          )}

          <div className="mt-3">
            {managementMode && (
              <label className="inline-flex items-center mt-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-gray-600"
                  checked={messageAcknowledgement}
                  onChange={(e) => setMessageAcknowledgement(e.target.checked)}
                />
                <span className="ml-2 text-gray-700">This message requires confirmation from the recipient</span>
              </label>
            )}
          </div>

          <div className="mt-3">
            {!managementMode && (
              <>
                <SelectDropDown
                  showLabel={
                    <>
                      Select Message Type
                      <br />
                      <small>For payroll inquiries, please select &#39;Payroll&#39;.</small>
                    </>
                  }
                  value={messageType.value}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    const selectedOption = messageOptions.find((option) => option.value === selectedValue);
                    if (selectedOption) {
                      setMessageType(selectedOption);
                    }
                  }}
                >
                  {messageOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </SelectDropDown>

                {messageType.value !== "payroll" && (
                  <div className="mt-3">
                    <SelectDropDown
                      showLabel="Select Rostered Shift"
                      value={selectedRosterId?.toString() || ""}
                      onChange={(e) => setSelectedRosterId(Number(e.target.value))}
                    >
                      <option value="">Select a Rostered Shift</option>
                      {filteredRoster.map((roster) => {
                        const { id, start, end, department, location } = roster;

                        return (
                          <option key={id} value={id}>
                            {formatRoster(start, end, location, department)}
                          </option>
                        );
                      })}
                    </SelectDropDown>
                  </div>
                )}

                {messageType.value === "payroll" && (
                  <PayrollInquire
                    selectedEmployee={configData && configData.currentEmployee}
                    body={newMessage}
                    setBody={setNewMessage}
                    endDate={payPeriodEndDate}
                    setPayPeriodEndDate={setPayPeriodEndDate}
                    tempAttachment={tempAttachment}
                    setTempAttachment={setTempAttachment}
                  />
                )}
              </>
            )}
          </div>
          <div className="flex mt-3">
            <Button
              disabled={!newMessage || (messageType.value === "payroll" && !payPeriodEndDate)}
              onClick={handleSendMessage}
              style={{ padding: "8px", marginRight: "8px" }}
            >
              Send
            </Button>
          </div>
        </div>
      </Modal>
      {rosterModal}
    </>
  );
}
