import * as Sentry from "@sentry/browser";
import { EmployeeProfile } from "components/EmployeeProfile";
import { EmployeeNotesModalProvider } from "helpers/employeeNotes";
import { createLoginLocation } from "helpers/redirectLogin";
import React from "react";
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { RosterComponent } from "roster/RosterComponent";
import { NavBar } from "settings/navbar";
import { ToastListener } from "store/errors/ErrorToaster";
import { useGetConfigQuery } from "store/reducers/config/configAPI";
import { SummaryComponent } from "summary";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import "./shared/fontawesome";

import { Availability } from "./availability/availability";
import { Button } from "./components/button";
import { NotFound } from "./components/NotFound";
import { LeaveComponent } from "./leave/leave";
import { Login } from "./login/login";
import { Messages } from "./messages/messages";
import { PublicHolidayComponent } from "./public_holidays/public_holidays";
import { SettingsComponent } from "./settings";
import { TimesheetViewer } from "./timesheet_viewer/index";

window.CLIENT_VERSION = import.meta.env.APP_VERSION;
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!window._original_fetch) {
  // for hot reload, don't update it agains
  window._original_fetch = window.fetch;
}
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoutes = () => {
  const { data: configData, isLoading } = useGetConfigQuery();
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return configData?.currentEmployee ? <Outlet /> : <Navigate to={createLoginLocation()} />;
};

type Props = {
  serverVersion: string;
  clientVersion: string;
};

function UpdateNotification(props: Props) {
  return (
    <div>
      New version {props.serverVersion}
      <Button colour="accent" onClick={() => window.location.reload()} className="ml-2">
        Update
      </Button>
    </div>
  );
}

const UPDATE_NOTIFICATION_TOAST_ID = "update notification id";

const App = function () {
  let [toastContent, setToastContent] = React.useState<any>();
  const { data: configData } = useGetConfigQuery();
  const location = useLocation();

  React.useEffect(() => {
    function checkForUpdate(SERVER_VERSION: string) {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        SERVER_VERSION != null &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        CLIENT_VERSION != null &&
        SERVER_VERSION != CLIENT_VERSION
      ) {
        console.warn({
          serverVersion: SERVER_VERSION,
          clientVersion: CLIENT_VERSION,
        });
        let content = <UpdateNotification clientVersion={CLIENT_VERSION} serverVersion={SERVER_VERSION} />;
        setToastContent(content);
      }
    }

    window.fetch = (...args) =>
      (async (args) => {
        // intercept all fetch and check if the version header matches
        let result = await window._original_fetch(...args);
        let SERVER_VERSION = result.headers.get("x-server-version");
        if (SERVER_VERSION) {
          checkForUpdate(SERVER_VERSION);
        }
        return result;
      })(args);
  }, []);

  React.useEffect(() => {
    toast(toastContent, {
      toastId: UPDATE_NOTIFICATION_TOAST_ID,
      type: "info",
    });
  }, [toastContent]);

  React.useEffect(() => {
    if (configData?.SENTRY_CLIENT_DSN != null) {
      let sentryOptions: Sentry.BrowserOptions = {
        dsn: configData.SENTRY_CLIENT_DSN,
      };
      if (import.meta.env.APP_VERSION) {
        sentryOptions.release = `radpay@${import.meta.env.APP_VERSION}`;
      }
      Sentry.init(sentryOptions);
    }
  }, [configData]);

  if (!configData) {
    return null;
  }

  return (
    <>
      <NavBar />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/timesheets" element={<TimesheetViewer />} />
          <Route path="/roster" element={<RosterComponent />} />
          <Route path="/leave" element={<LeaveComponent />} />
          <Route path="/availability" element={<Availability />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/summary/*" element={<SummaryComponent />} />
          <Route path="/settings/*" element={<SettingsComponent />} />
          <Route path="/public-holidays" element={<PublicHolidayComponent />} />
          <Route
            path="/profile"
            element={
              configData.currentEmployee ? (
                <EmployeeProfile employeeId={configData.currentEmployee.id} />
              ) : (
                <div>Loading...</div>
              )
            }
          />
        </Route>

        {/* redirect the old /attendance route to /login */}
        <Route path="/attendance" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={configData.currentEmployee ? <Navigate to="/timesheets" /> : <Navigate to={createLoginLocation()} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export function Providers() {
  return (
    <Router>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <EmployeeNotesModalProvider>
          <ToastListener />
          <App />
          <ToastContainer />
        </EmployeeNotesModalProvider>
      </QueryParamProvider>
    </Router>
  );
}
