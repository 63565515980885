import { UnknownAction, isRejectedWithValue } from "@reduxjs/toolkit";
import { createLoginLocation } from "helpers/redirectLogin";
import React from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch } from "store/hooks";
import { attendanceApi } from "store/reducers";
import { addAppListener } from "store/store";

const SESSION_EXPIRED_TOAST_ID = "Login session expired toast";

export function ToastListener() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  function handleAction(action: UnknownAction) {
    // Perform type narrowing against the action
    if (!isRejectedWithValue(action)) {
      return;
    }
    // Perform type narrowing against the action (again)
    if (
      action.payload != null &&
      typeof action.payload === "object" &&
      "data" in action.payload &&
      action.payload.data != null &&
      typeof action.payload.data === "object" &&
      "description" in action.payload.data &&
      "code" in action.payload.data &&
      "name" in action.payload.data &&
      typeof action.payload.data.description === "string" &&
      typeof action.payload.data.code === "number" &&
      typeof action.payload.data.name === "string" &&
      "status" in action.payload &&
      typeof action.payload.status === "number"
    ) {
      if (
        action.payload.status === 401 &&
        // submitPin 401 should not redirect
        !attendanceApi.endpoints.submitPin.matchRejected(action)
      ) {
        // Ignore this error because it's handled in the component
        if (location.pathname !== "/login") {
          navigate(createLoginLocation());
        }
        toast("Login session expired. Please login again.", {
          toastId: SESSION_EXPIRED_TOAST_ID,
          type: "error",
        });
      } else {
        const message = action.payload.data.description;
        const name = action.payload.data.name;
        const toastContent = (
          <div>
            <div className="font-semibold">{name}</div>
            <div>{message}</div>
            <div className="text-xs">{action.type}</div>
          </div>
        );

        toast(toastContent, { type: "error" });
      }
    }
  }

  React.useEffect(() => {
    const unsubscribe = dispatch(
      addAppListener({
        predicate: (action, _currentState, _previousState) => {
          return isRejectedWithValue(action);
        },
        effect: async (action, _listenerApi) => {
          handleAction(action);
        },
      }),
    );

    // TODO: The returned type from dispatch is wrong
    return unsubscribe as unknown as (cancelOptions?: any) => void;
  });

  return null; // This component doesn't render anything itself
}
