import React, { useState } from "react";
import { useAppSelector } from "store/hooks";
import { selectIsManager } from "store/reducers";
import { useUpdateOvertimeMutation } from "store/reducers/overtime";
import { OvertimeNoteModal } from "timesheet_viewer/OvertimeNoteModal";

import { Button } from "./button";
import { OvertimeStatus } from "./OvertimeStatus";

interface OvertimeButtonProps {
  date: string;
  timesheetEmployeeId: number;
  currentEmployeeId?: number;
  overtime: Overtime | undefined;
  parent: "TIMESHEET" | "SUMMARY";
  overtimeDuration: number | null;
}

export function OvertimeButtons({
  date,
  timesheetEmployeeId,
  currentEmployeeId,
  overtime,
  parent,
  overtimeDuration,
}: OvertimeButtonProps) {
  const [overtimeModalIsOpen, setOvertimeModalIsOpen] = useState(false);

  const isManager = useAppSelector(selectIsManager);

  const [updateOvertime] = useUpdateOvertimeMutation();

  function approveOvertime(overtimeId: number) {
    updateOvertime({
      id: overtimeId,
      status: "approved",
      employee_id: timesheetEmployeeId,
      when: date,
    });
  }

  function declineOvertime(overtimeId: number) {
    updateOvertime({
      id: overtimeId,
      status: "declined",
      employee_id: timesheetEmployeeId,
      when: date,
    });
  }

  if (parent === "TIMESHEET" && overtimeDuration === null) {
    return null;
  }

  return (
    <div className="flex justify-center gap-1">
      {/* Overtime status view for managers and requesting employees */}
      {overtime && (
        <>
          {parent === "TIMESHEET" && overtime.status === "pending" && (
            <Button colour="primary" size="sm" title="Your overtime request is pending approval">
              Overtime <OvertimeStatus overtimeStatus={overtime.status} />
            </Button>
          )}
          {overtime.status === "declined" && (
            <Button colour="danger" size="sm" title="Overtime request has been declined">
              Overtime <OvertimeStatus overtimeStatus={overtime.status} />
            </Button>
          )}
          {overtime.status === "approved" && (
            <Button colour="success" size="sm" title="Overtime request has been approved">
              Overtime <OvertimeStatus overtimeStatus={overtime.status} />
            </Button>
          )}
        </>
      )}

      {/* Manager and employee need to apply overtime */}
      {(currentEmployeeId === timesheetEmployeeId || isManager) && !overtime && (
        <Button
          onClick={() => setOvertimeModalIsOpen(true)}
          colour="accent"
          size="sm"
          title="Click here to request overtime approval"
        >
          Apply Overtime
        </Button>
      )}

      {/* Manager view */}
      {isManager && overtime && overtime.status === "pending" && (
        <>
          <Button
            onClick={() => approveOvertime(overtime.id)}
            colour="success"
            size="sm"
            title="Approve this overtime request"
          >
            Approve
          </Button>
          <Button
            onClick={() => declineOvertime(overtime.id)}
            colour="danger"
            size="sm"
            title="Decline this overtime request"
          >
            Decline
          </Button>
        </>
      )}

      {overtimeModalIsOpen && (
        <OvertimeNoteModal
          isOpen={overtimeModalIsOpen}
          dismissModal={() => setOvertimeModalIsOpen(false)}
          day={date}
          employeeId={timesheetEmployeeId}
          title="Overtime Shift Notes"
          overtimeDuration={overtimeDuration}
        />
      )}
    </div>
  );
}
