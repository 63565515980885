import React from "react";
import { useAppDispatch } from "store/hooks";
import { LeaveFilterType, fetchLeaveApplications } from "store/reducers";

import { LeaveRequests } from "./leave_requests";
import { useHandleResponseError } from "../helpers/useHandleResponseError";
import { PayPeriod } from "../timesheet_viewer/payperiod";
import { useStartDate } from "../timesheet_viewer/useStartDate";

export function LeaveManagement() {
  // Filter leave applications (PENDING, APPROVED, ARCHIVED, ALL)
  const [filterType, setFilterType] = React.useState<LeaveFilterType>("PENDING");
  const handleResponseError = useHandleResponseError();
  const [startDate, endDate] = useStartDate(handleResponseError);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(
      fetchLeaveApplications({
        filterType,
        xero_PayPeriodEndDate: (filterType !== "PENDING" && endDate) || undefined,
      }),
    );
  }, [filterType, endDate]);

  return (
    <div>
      <div className="flex flex-row items-center mb-2">
        {filterType !== "PENDING" && <PayPeriod startDate={startDate} endDate={endDate} />}
        <div className="flex flex-row justify-end ml-auto">
          {(["PENDING", "APPROVED", "ARCHIVED", "ALL"] as LeaveFilterType[]).map((filter_type) => {
            return (
              <label key={filter_type} className="block text-primary font-bold cursor-pointer mr-2">
                <input
                  className="mr-2 leading-tight cursor-pointer"
                  type="radio"
                  onChange={() => setFilterType(filter_type)}
                  checked={filterType === filter_type}
                />
                <span className="text-sm">{filter_type}</span>
              </label>
            );
          })}
        </div>
      </div>
      <LeaveRequests />
    </div>
  );
}
