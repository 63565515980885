import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { shiftApi } from "../shift/shiftApi";

type CreateOvertime = Pick<Overtime, "employee_id" | "when" | "reason"> & {
  status?: Overtime["status"];
};

type UpdateOvertime = Partial<Overtime> & { id: number };

export type GetOvertimes = {
  start_date: string;
  status?: OvertimeStatus[];
};

export const overtimeApi = createApi({
  reducerPath: "overtimeApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getOvertimes: builder.query<Overtime[], GetOvertimes>({
      query: ({ start_date, status }) => {
        return {
          url: `/api/overtime`,
          method: "GET",
          params: {
            start_date,
            status,
          },
        };
      },
    }),

    createOvertime: builder.mutation<Overtime, CreateOvertime>({
      query: (body) => ({
        url: "/api/overtime",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      }),
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(shiftApi.util.invalidateTags(["WorkedShift"]));
      },
    }),

    updateOvertime: builder.mutation<Overtime, UpdateOvertime>({
      query: ({ id, ...rest }) => ({
        url: `/api/overtime/${id}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: rest,
      }),
    }),
  }),
});

export const { useGetOvertimesQuery, useCreateOvertimeMutation, useUpdateOvertimeMutation } = overtimeApi;
