import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/button";
import { format } from "date-fns";
import React from "react";
import { useAppSelector } from "store/hooks";
import { selectRosteredShiftById, ShiftObj } from "store/reducers";

import { TableCell } from "./table_cell";

type Props = {
  rosteredShiftId: number;
  revertShiftToRosteredHours: (rosteredShift: RosteredShift) => void;
  revertStartTime?: (rosteredShift: RosteredShift, shiftObj: ShiftObj) => void;
  revertEndTime?: (rosteredShift: RosteredShift, shiftObj: ShiftObj) => void;
  revertToShift?: ShiftObj;
};

export const RosteredShiftRow: React.FC<Props> = (props) => {
  const { rosteredShiftId, revertShiftToRosteredHours, revertStartTime, revertEndTime, revertToShift } = props;
  const rosteredShift = useAppSelector((state) => selectRosteredShiftById(state, rosteredShiftId));
  if (rosteredShift === undefined) {
    return null;
  }

  return (
    <tr key={rosteredShiftId}>
      <TableCell shiftType="rostered_shift">
        <span className="cursor-pointer font-medium text-primary-dark" title="Rostered shift">
          R
        </span>
      </TableCell>
      <TableCell shiftType="rostered_shift">
        <div className="grid place-items-center">
          {format(new Date(rosteredShift.start), "h:mm a")}
          {revertToShift && revertStartTime && (
            <Button
              size="sm"
              onClick={() => revertStartTime(rosteredShift, revertToShift)}
              title="Revert the above shift to the rostered start time"
            >
              Revert
            </Button>
          )}
        </div>
      </TableCell>
      <TableCell shiftType="rostered_shift">
        <div className="grid place-items-center">
          {format(new Date(rosteredShift.end), "h:mm a")}
          {revertToShift && revertEndTime && (
            <Button
              size="sm"
              onClick={() => revertEndTime(rosteredShift, revertToShift)}
              title="Revert the above shift to the rostered end time"
            >
              Revert
            </Button>
          )}
        </div>
      </TableCell>
      <TableCell shiftType="rostered_shift" />
      <TableCell shiftType="rostered_shift" />
      <TableCell shiftType="rostered_shift">{rosteredShift.department.name}</TableCell>
      <TableCell shiftType="rostered_shift">
        <div>{rosteredShift.location.name}</div>
        <div>{rosteredShift.notes}</div>
      </TableCell>
      <TableCell>
        <Button
          onClick={() => revertShiftToRosteredHours(rosteredShift)}
          title="Create a new shift with exactly the rostered hours"
          size="lg"
        >
          <FontAwesomeIcon icon="history" /> Revert
        </Button>
      </TableCell>
    </tr>
  );
};
