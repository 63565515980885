import React from "react";
import { useAppSelector } from "store/hooks";
import { selectAllLocations } from "store/reducers/location/locationSlice";

import { SelectDropDown } from "../components/selectDropDown";

type Props = {
  showUnspecified?: boolean;
  showDeleted?: boolean;
} & React.ComponentProps<typeof SelectDropDown>;

export const LocationDropdown = (props: Props) => {
  const { showUnspecified = true, showDeleted = true, ...rest } = props;
  let mriLocations = useAppSelector(selectAllLocations);
  if (!mriLocations.length) return null;

  if (!showDeleted) {
    mriLocations = mriLocations.filter((location) => !location.deleted);
  }

  return (
    <SelectDropDown {...rest}>
      {mriLocations.map((site) => (
        <option key={site.id} value={site.id}>
          {site.name}
        </option>
      ))}
      {showUnspecified && <option value="">Unspecified</option>}
    </SelectDropDown>
  );
};
