import React from "react";
import { useAppSelector } from "store/hooks";
import { selectIsManager, useGetConfigQuery } from "store/reducers";
import { selectThreads } from "store/reducers/message/messageSlice";

import { NewThreadModal } from "./new_thread_modal";
import { Button } from "../components/button";

interface NewThreadProps {
  managementMode: boolean;
  title: string;
  isGroupMessage: boolean;
}

export function NewThread(props: NewThreadProps) {
  const { managementMode, title, isGroupMessage } = props;
  const [newThreadModal, setNewThreadModal] = React.useState<React.ReactNode>();
  const { data: configData } = useGetConfigQuery();
  const isManagerCheck = useAppSelector(selectIsManager);
  const isManager = isManagerCheck === true && managementMode === true;

  const threads = useAppSelector(selectThreads);

  function openNewThreadModal() {
    setNewThreadModal(
      <NewThreadModal
        dismissModal={() => setNewThreadModal(undefined)}
        managementMode={managementMode}
        isGroupMessage={isGroupMessage}
        title={title}
      />,
    );
  }

  function existingThreadForNonManager() {
    // check if the current user is a manager and if they have an existing thread
    // a manager should always be able to create a new thread
    // a non-manager should only be able to create a new thread if they don't have an existing thread
    const currentEmployeeId = configData?.currentEmployee?.id;
    const currentEmployeeIsManager = isManager;
    const existingThread = threads.filter((message) => message.employee_id === currentEmployeeId);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return currentEmployeeIsManager || (existingThread.length ?? 0) === 0;
  }

  return (
    <div>
      {existingThreadForNonManager() && <Button onClick={() => openNewThreadModal()}>{title}</Button>}
      {newThreadModal}
    </div>
  );
}
