import { PayrollInquire } from "components/PayrollInquire";
import { format } from "date-fns";
import { filteredDateRoster, formatRoster } from "helpers/messages";
import React from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectIsManager, selectRosterByEmployeeId, useFetchEmployeesQuery, useGetConfigQuery } from "store/reducers";
import { sendGroupMessage, sendMessage } from "store/reducers/message/messageAPI";
import { selectThreads } from "store/reducers/message/messageSlice";
import { createEmployeeOptions } from "utils/employee_options";
import { messageOptions, MessageOption } from "utils/message_options";

import { Button } from "../components/button";
import { EmployeeComboBox } from "../components/employeeComboBox";
import { Modal, ModalProps } from "../components/modal";
import { SelectDropDown } from "../components/selectDropDown";
import { TextArea } from "../components/textarea";

type NewThreadModalProps = {
  messages?: Message[];
  employee?: Employee | null;
  managementMode?: boolean;
  isGroupMessage: boolean;
} & ModalProps;

export function NewThreadModal(props: NewThreadModalProps) {
  const { employee, managementMode, isGroupMessage, ...rest } = props;

  const [body, setBody] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<MessageOption>({
    value: messageOptions[0].value,
    label: messageOptions[0].label,
  });
  const [addMessage, setAddMessage] = React.useState(false);
  const [selectedRosterId, setSelectedRosterId] = React.useState<number | undefined>(undefined);

  const [payPeriodEndDate, setPayPeriodEndDate] = React.useState<Date | null>(null);

  const [tempAttachment, setTempAttachment] = React.useState<Upload | undefined>(undefined);

  const { data: employees } = useFetchEmployeesQuery(undefined);

  const dispatch = useAppDispatch();

  const { data: configData } = useGetConfigQuery();
  const isManagerCheck = useAppSelector(selectIsManager);

  const threads = useAppSelector(selectThreads);

  const isManager = isManagerCheck === true && managementMode === true;

  const [selectedEmployee, setSelectedEmployee] = React.useState<Employee | null | undefined>(employee);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = React.useState<number[]>([]);

  const [messageAcknowledgement, setMessageAcknowledgement] = React.useState(false);

  // filter out employees that already have a message thread
  const employeeIds = Array.from(new Set(threads.map((message) => message.employee_id)));
  const filteredEmployees =
    employees?.employees.filter(
      (employee) => employee.id !== configData?.currentEmployee?.id && !employeeIds.includes(employee.id),
    ) ?? [];

  const employeeRoster = useAppSelector((state) => selectRosterByEmployeeId(state, configData!.currentEmployee!.id));

  const filteredRoster = filteredDateRoster(employeeRoster);

  const employeeOptions = React.useMemo(() => createEmployeeOptions(employees?.employees), [employees]);

  const selectedEmployeeOptions = React.useMemo(() => {
    return employeeOptions.filter((option) => selectedEmployeeIds.includes(option.value));
  }, [selectedEmployeeIds, employeeOptions]);

  React.useEffect(() => {
    if (!addMessage) {
      setBody("");
    }
  }, [addMessage]);

  function handleSubmit() {
    let data = {
      body: body ? body : messageType.label,
      employee_id: selectedEmployee?.id,
      rostered_shift_id: selectedRosterId,
      type: messageType.value,
      requires_ack: messageAcknowledgement,
      pay_period_ending: payPeriodEndDate ? format(new Date(payPeriodEndDate), "yyyy-MM-dd") : null,
      attachment_filename: tempAttachment ? tempAttachment.filename : null,
    };

    if (configData?.currentEmployee?.id) {
      dispatch(
        sendMessage({
          data,
          currentEmployeeId: configData.currentEmployee.id,
        }),
      );
    }

    props.dismissModal();
  }

  function handleGroupSubmit() {
    let data = {
      employee_ids: selectedEmployeeIds,
      message: {
        body: body ? body : messageType.label,
        rostered_shift_id: selectedRosterId,
        type: messageType.value,
        requires_ack: messageAcknowledgement,
        pay_period_ending: null,
        attachment_filename: null,
      },
    };
    dispatch(
      sendGroupMessage({
        data,
      }),
    );

    props.dismissModal();
  }

  function isGroupMessageDisabled(): boolean {
    return isManager && isGroupMessage && (selectedEmployeeIds.length === 0 || !body);
  }

  function isIndividualMessageDisabled(): boolean {
    return isManager && !isGroupMessage && (!selectedEmployee || !body);
  }

  function isNonManagerMessageDisabled(): boolean {
    return (
      !isManager &&
      ((messageType.value === "other" && !body) || (messageType.value === "payroll" && (!payPeriodEndDate || !body)))
    );
  }

  return (
    <Modal
      buttons={
        <Button
          disabled={isGroupMessageDisabled() || isIndividualMessageDisabled() || isNonManagerMessageDisabled()}
          onClick={() => (isGroupMessage ? handleGroupSubmit() : handleSubmit())}
          colour="accent"
        >
          Submit
        </Button>
      }
      {...rest}
    >
      {isManager && !isGroupMessage && (
        <EmployeeComboBox
          defaultValue={selectedEmployee}
          employees={filteredEmployees}
          onSelectEmployee={(employee) => setSelectedEmployee(employee)}
        />
      )}
      {isManager && isGroupMessage && (
        <Select
          closeMenuOnSelect={false}
          menuPortalTarget={document.body}
          isMulti
          options={employeeOptions}
          value={selectedEmployeeOptions}
          onChange={(selectedOptions) => {
            setSelectedEmployeeIds(selectedOptions.map((option) => option.value));
          }}
        />
      )}
      {!isManager && (
        <>
          <SelectDropDown
            showLabel={
              <>
                Select Message Type
                <br />
                <small>For payroll inquiries, please select &#39;Payroll&#39;.</small>
              </>
            }
            value={messageType.value}
            onChange={(e) => {
              const selectedValue = e.target.value;
              const selectedOption = messageOptions.find((option) => option.value === selectedValue);
              if (selectedOption) {
                setMessageType({
                  value: selectedOption.value,
                  label: selectedOption.label,
                });
              }
            }}
          >
            {messageOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </SelectDropDown>
          {messageType.value !== "payroll" && (
            <div className="mt-3">
              <SelectDropDown
                showLabel="Select Rostered Shift"
                value={selectedRosterId?.toString() || ""}
                onChange={(e) => setSelectedRosterId(Number(e.target.value))}
              >
                <option value="">Select a Rostered Shift</option>
                {filteredRoster.map((roster) => {
                  const { id, start, end, department, location } = roster;

                  return (
                    <option key={id} value={id}>
                      {formatRoster(start, end, location, department)}
                    </option>
                  );
                })}
              </SelectDropDown>
            </div>
          )}

          {messageType.value !== "other" && messageType.value !== "payroll" && (
            <div>
              <label className="inline-flex items-center mt-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-gray-600"
                  checked={addMessage}
                  onChange={(e) => setAddMessage(e.target.checked)}
                />
                <span className="ml-2 text-gray-700">Optional: Add a message (not required)</span>
              </label>
            </div>
          )}
        </>
      )}

      {isManager && (
        <>
          <div className="w-full">
            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold my-2">Body</label>
            <TextArea
              name="Body"
              placeholder="Message..."
              value={body}
              onChange={(e) => setBody(e.target.value)}
              maxLength={500}
              autoFocus
            />
          </div>
          <div className="mt-3">
            <label className="inline-flex items-center mt-3">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-gray-600"
                checked={messageAcknowledgement}
                onChange={(e) => setMessageAcknowledgement(e.target.checked)}
              />
              <span className="ml-2 text-gray-700">This message requires confirmation from the recipient</span>
            </label>
          </div>
        </>
      )}

      {!isManager && (messageType.value === "other" || addMessage) && (
        <div className="w-full">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold my-2">Body</label>
          <TextArea
            name="Body"
            placeholder="Message..."
            value={body}
            onChange={(e) => setBody(e.target.value)}
            maxLength={500}
          />
        </div>
      )}

      {messageType.value === "payroll" && (
        <PayrollInquire
          selectedEmployee={configData && configData.currentEmployee}
          body={body}
          setBody={setBody}
          endDate={payPeriodEndDate}
          setPayPeriodEndDate={setPayPeriodEndDate}
          tempAttachment={tempAttachment}
          setTempAttachment={setTempAttachment}
        />
      )}
    </Modal>
  );
}
